function Privacypolicy() {
  return (
    <div>
      <h2
        style={{ paddingLeft: '7rem', paddingTop: '2rem', textAlign: 'left' }}
      >
        PRIVACY POLICY FOR PRELAB
      </h2>
      <div className='terms-container'>
        <h3 className='terms-title'>1. Introduction</h3>
        <h6>
          This document summarises Prelab’s processing of personal data. Prelab
          protects your personal privacy. We always strive for a high level of
          data protection and aim to comply with the rules and principles in the
          General Data Protection Regulation.
        </h6>
        <h3 className='terms-title'>2. Basic principles and rights</h3>
        <h4 className='terms-subtitle'>
          2.1 Principles for the processing of personal data
        </h4>
        <h6>
          Prelab complies with the following basic principles:
          <ul className='terms-table'>
            <li>
              No personal data are collected or processed without a legal basis;
            </li>
            <li>
              Personal data must be processed lawfully, fairly and in a
              transparent manner in relation to the data subject;
            </li>
            <li>
              Personal data may only be collected for specified, explicit and
              legitimate purposes, as listed in Chapter 5 below;
            </li>
            <li>
              Personal data must not be processed in a manner that is
              incompatible with these purposes;
            </li>
            <li>
              Personal data must be adequate, relevant and limited to what is
              necessary in relation to the purposes for which they are
              processed;
            </li>
            <li>
              The personal data collected must not be processed if they are too
              old to be relevant for the initial purposes;
            </li>
            <li>Personal data shall be correct and kept up to date;</li>
            <li>
              Personal data must not be stored for longer than is necessary;{' '}
            </li>
            <li>
              Personal data shall be protected, including against unauthorised
              or unlawful processing and against loss, destruction or damage.
            </li>
          </ul>
        </h6>
        <h4 className='terms-subtitle'>
          2.2 Principles for the processing of personal data
        </h4>
        <h6>
          Prelab has procedures for personal data that are consistent with the
          data subject’s rights:<br></br>Right to information
          <ul className='terms-table'>
            <li>
              Information on the processing of personal data is provided both at
              the time of registration and when requested by the data subject;
            </li>
            <li>
              The data subject is informeded in case of any incidents, such as
              in case of a data breach;
            </li>
            <li>
              The information is provided to the data subject free of charge and
              in an accessible format.
            </li>
          </ul>
          Right to rectification
          <ul className='terms-table'>
            <li>
              The data subject has a right to supplement personal data that are
              missing or inaccurate.
            </li>
          </ul>
          Right to erasure
          <ul className='terms-table'>
            <li>
              The data subject may request that his or her data are erased;
            </li>
          </ul>
          Right to restriction of processing
          <ul className='terms-table'>
            <li>
              Restriction means that the data is marked so that in the future,
              they can only be processed for certain limited purposes.
            </li>
          </ul>
          Data portability
          <ul className='terms-table'>
            <li>
              The data subject may request to have his or her personal data
              exported in a machine-readable format.
            </li>
          </ul>
          Right to object
          <ul className='terms-table'>
            <li>
              The data subject has a right to object to the processing of
              personal data;
            </li>
            <li>
              If an objection is made against direct marketing, the personal
              data must no longer be processed for such purposes.
            </li>
          </ul>
        </h6>
        <h3 className='terms-title'>3. Procedures</h3>
        <h6>
          The general guidelines and principles form the basis for all
          processing of personal data. Prelab has implemented the following
          procedures for the processing of personal data:
        </h6>
        <h4 className='terms-subtitle'>3.1 Organisational security measures</h4>
        <h6>
          Prelab strives to achieve a good level of security for personal data,
          including the use of the following security measures:
          <ul className='terms-table'>
            <li>Data minimisation;</li>
            <li>Password management;</li>
            <li>Monitoring and training of employees;</li>
            <li>Encrypted computers equipped with antivirus software;</li>
            <li>
              Images of personal data are only used if there is an agreement in
              place;
            </li>
            <li>
              All customer communication contains a footer with a link to the
              personal data policy;
            </li>
            <li>
              Anonymisation is applied when personal data are communicated
              internally (if possible).
            </li>
          </ul>
        </h6>

        <h4 className='terms-subtitle'>3.2 Technical security measures</h4>
        <h6>
          <ul className='terms-table'>
            <li>Pro-active efforts to prevent data breaches;</li>
            <li>HTTPS;</li>
            <li>No open APIs;</li>
          </ul>
        </h6>
        <h4 className='terms-subtitle'>3.3 In case of incidents</h4>
        <h6>
          According to the data protection reform, a personal data breach is
          defined as a breach of security leading to the accidental or unlawful
          destruction, loss or alteration of the processed personal data.
          <br></br>
          <br></br>If a personal data breach is discovered, it is reported to
          the product owner or to an employee who is considered to have a good
          overview of the system in question. This person is then responsible
          for notifying the breach within 72 hours. Thereafter, the incident
          shall be archived and added to the archive of historic incidents in
          this document (Appendix 1).
        </h6>
        <h4 className='terms-subtitle'>3.4 Special rules for children</h4>
        <h6>
          Children under the age of 16 are not allowed to use Prelab’s website
          to provide any personal data and the Data Protection Regulations
          concerning enhanced protection of children's personal data is
          therefore not actualized.
        </h6>
        <h3 className='terms-title'>4. List of personal data and processing</h3>
        <h6>
          This chapter contains a list of all personal data held by Prelab and
          the processing of such personal data.
        </h6>
        <h4 className='terms-subtitle'>4.1 Prelab</h4>
        <h6 style={{ marginBottom: '0.4rem' }}>
          The user creates an project, using his or her email address
        </h6>
        <h6>
          <b>Purpose:</b> The user wants to create a project<br></br>
          <b>Data:</b> first name, last name, email address, property address,
          property images, reference images for property<br></br>
          <b>Legal basis:</b> Agreement (Legitimate interest in case the user is
          a legal entity)<br></br>
        </h6>
        <h4 className='terms-subtitle'>4.2 Cookies</h4>
        <h6>
          A cookie is a text file that is stored in the web browser. Prelab
          website use several different cookies:
        </h6>
        <h6>
          <ul className='terms-table'>
            <li>
              Session cookies are temporary cookies that cease to exist when the
              web browser is closed;
            </li>
            <li>
              Persistent cookies are stored in the web browser for a specific
              number of days;
            </li>
            <li>
              Third party cookies are added by third party websites (such as
              Google analytics);
            </li>
            <li>
              The cookies stored by Prelab in the web browser are fundamental
              for the functionality of the service. For example, there are no
              "save buttons" in Prelab. Instead, the content is saved in a
              cookie until it is sent to the database for permanent storage;
            </li>
            <li>
              The third party cookies used by Prelab are from well-established
              services, such as Google Analytics. These cookies save
              comprehensive information on how the website is used.
            </li>
          </ul>
        </h6>
        <h6>
          <b>Purpose:</b> Technology that is required for the functioning of the
          programme and for general analyses
        </h6>
        <br></br>
        <h6>
          <b>Legal basis:</b> Agreement
        </h6>
        <h3 className='terms-title'>5. Appendecies</h3>
        <h3 className='terms-title'>6. Historic personal data incidents</h3>
        <h6>No incident have occured.</h6>
      </div>
    </div>
  );
}

export default Privacypolicy;
