import React from 'react';
import { useMemo } from 'react';
import { getAuth } from 'firebase/auth';
import { RichTextEditor } from '@mantine/rte';
function AdminCard({
  _actualUser,
  _deleteUser,
  _auth,
  _authUser,
  _openPricingForm,
}) {
  if (_auth) {
    const auth = getAuth();
    if (auth === _auth.auth) {
      // Using fetch
      async function imgDownload(imageSrc) {
        const image = await fetch(imageSrc.src);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);
        // Get the file format
        let imageFormat = '.jpg';
        if (imageBlog.type === 'image/jpeg') {
          imageFormat = '.jpg';
        }
        const link = document.createElement('a');
        link.href = imageURL + imageFormat;
        link.download = imageSrc.name;
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
      }

      const admGen = (usersAll) => {
        return usersAll.map((user, i) => {
          // const getUserImages = function (_user) {
          //   _user.forEach((item) => {
          //   });
          // };
          const userArr = Object.entries(user);
          const likecArr = user.colorLike.split(', ');
          const dislikecArr = user.colorDislike.split(', ');
          // getUserImages(_actualUser);
          const allImages = new Array(19);
          allImages.fill('');
          return (
            <div className="adm__wrapper" key={i}>
              <div className="adm__wrapper--bg">
                <div className="adm__card fadeInUp">
                  <p className="adm__date">{user.date}</p>
                  <div className="adm__cont">
                    <p>ID: {user.id}</p>
                    <p>Name: {user.name}</p>
                    <p>
                      Email: {user.email}{' '}
                      {user.subbednews && (
                        <i
                          className="adm__fa fa fa-rocket"
                          alt="Subscribed to news"
                          title="Subscribed to news"
                        ></i>
                      )}
                    </p>
                    <p>Property Address: {user.address}</p>
                    <p>Style: {user.style}</p>
                    <p style={{ marginTop: '1rem' }}>Description:</p>
                    <div id="admDesc">
                      <RichTextEditor readOnly value={user.desc} />
                    </div>
                  </div>
                  <div className="adm__cont">
                    <p>Images:</p>
                    <div className="adm__imgs">
                      {allImages.map((img, i) => {
                        const k = Object.keys(user).find(
                          (key) => key === `img${i}`
                        );
                        const rs = Object.keys(user).find(
                          (key) => key === `roomselected${i}`
                        );
                        const os = Object.keys(user).find(
                          (key) => key === `selected${i}`
                        );
                        // V -image, U- roomtype, Z- pricingform
                        const v = user[k];
                        const u = user[rs];
                        const z = user[os];
                        if (v) {
                          return (
                            <figure key={i}>
                              <img src={v} className="checkout-img"></img>
                              <figcaption>
                                {/* <h5 className="checkout-img-price">({u})</h5>
                                <h5 className="checkout-img-price">({z})</h5> */}
                                <button
                                  className="btnMain"
                                  onClick={() => {
                                    _openPricingForm(0, true, u, z);
                                  }}
                                >
                                  Form
                                </button>
                              </figcaption>
                              {/* <div
                                className="adm__download"
                                onClick={(e) => {
                                  imgDownload(
                                    e.target.closest('figure').firstElementChild
                                  );
                                }}
                              >
                                <svg
                                  width="24"
                                  height="auto"
                                  viewBox="0 0 72 96"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M42 25.5V0H4.5C2.00625 0 0 2.00625 0 4.5V91.5C0 93.9938 2.00625 96 4.5 96H67.5C69.9938 96 72 93.9938 72 91.5V30H46.5C44.025 30 42 27.975 42 25.5ZM56.3344 65.13L38.2556 83.0737C37.0087 84.3131 34.995 84.3131 33.7481 83.0737L15.6694 65.13C13.7663 63.2419 15.1012 60 17.7787 60H30V45C30 43.3425 31.3425 42 33 42H39C40.6575 42 42 43.3425 42 45V60H54.2212C56.8987 60 58.2338 63.2419 56.3344 65.13ZM70.6875 19.6875L52.3313 1.3125C51.4875 0.46875 50.3438 0 49.1438 0H48V24H72V22.8563C72 21.675 71.5312 20.5312 70.6875 19.6875Z"
                                    fill="black"
                                  />
                                </svg>
                              </div> */}
                            </figure>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className="adm__cont">
                    <p>References:</p>
                    <div className="adm__imgs">
                      {allImages.map((img, i) => {
                        const k = Object.keys(user).find(
                          (key) => key === `ref${i}`
                        );
                        const v = user[k];

                        if (v) {
                          return (
                            <figure key={i}>
                              <img src={v} className="checkout-img"></img>
                              <figcaption></figcaption>
                              {/* <div
                                className="adm__download"
                                onClick={(e) => {
                                  imgDownload(
                                    e.target.closest('figure').firstElementChild
                                  );
                                }}
                              >
                                <svg
                                  width="24"
                                  height="auto"
                                  viewBox="0 0 72 96"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M42 25.5V0H4.5C2.00625 0 0 2.00625 0 4.5V91.5C0 93.9938 2.00625 96 4.5 96H67.5C69.9938 96 72 93.9938 72 91.5V30H46.5C44.025 30 42 27.975 42 25.5ZM56.3344 65.13L38.2556 83.0737C37.0087 84.3131 34.995 84.3131 33.7481 83.0737L15.6694 65.13C13.7663 63.2419 15.1012 60 17.7787 60H30V45C30 43.3425 31.3425 42 33 42H39C40.6575 42 42 43.3425 42 45V60H54.2212C56.8987 60 58.2338 63.2419 56.3344 65.13ZM70.6875 19.6875L52.3313 1.3125C51.4875 0.46875 50.3438 0 49.1438 0H48V24H72V22.8563C72 21.675 71.5312 20.5312 70.6875 19.6875Z"
                                    fill="black"
                                  />
                                </svg>
                              </div> */}
                            </figure>
                          );
                        }
                      })}
                    </div>
                  </div>

                  <div className="adm__cont">
                    <div className="adm__colors">
                      <p>Like:</p>
                      {likecArr.map((item) => {
                        return (
                          <div className="adm__ccc">
                            <div
                              className="adm__boxcolor"
                              style={{ background: `${item}` }}
                            ></div>
                            <p>{item}</p>
                          </div>
                        );
                      })}
                    </div>
                    <div className="adm__colors">
                      <p>Dislike:</p>
                      {dislikecArr.map((item) => {
                        return (
                          <div className="adm__ccc">
                            <div
                              className="adm__boxcolor"
                              style={{ background: `${item}` }}
                            ></div>
                            <p>{item}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="adm__cont">
                    <p>Promo: {user.promo}</p>
                  </div>
                  <div className="adm__cont">
                    <p>Total: {user.sum}</p>
                  </div>
                  <button
                    className="btnMain"
                    style={{ minWidth: '10rem' }}
                    onClick={() => {
                      const isExecuted = window.confirm(
                        'Are you sure to execute this action?'
                      );
                      isExecuted && _deleteUser(user.id);
                    }}
                  >
                    Delete Record
                  </button>
                </div>
              </div>
            </div>
          );
        });
      };
      // console.log(_actualUser);
      // const sortedArr = _actualUser.map((obj) => {
      //   console.log(obj.date);
      //   // return { ...obj, date: new Date(obj.date) };
      // });

      _actualUser.sort(function (a, b) {
        // return Number(b.date) - Number(a.date);
        return b.date.localeCompare(a.date);
      });

      // console.log(sortedArr);
      const admGenResult = admGen(_actualUser);
      return <div>{admGenResult}</div>;
    }
  }
}

export default AdminCard;
