// React
import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { React } from 'react';
import { Link } from 'react-router-dom';
import Cookie from './components/Cookie';

// Routes
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
  withGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import './App.css';

// Custom libs
import { RichTextEditor } from '@mantine/rte';
import { v4 } from 'uuid';
import { ChromePicker } from 'react-color';
// Firebase
import { auth, db, storage } from './firebase-config';

import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getAuth,
  signOut,
  signInAnonymously,
} from 'firebase/auth';

import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';

import {
  ref,
  getStorage,
  uploadBytes,
  listAll,
  list,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

// Components
import Lightbox from './components/Lightbox';
import Privacypolicy from './components/Privacypolicy';
import AdminCard from './components/AdminCard';
import Home from './components/Home';
import NotFound from './pages/NotFound';
import Loadingscreen from './components/Loadingscreen';
import Revealer from './components/Revealer';
// - - - - - - - - - - - - - - - - //
// * * * * GLOBAL VARIABLES * * * * //
// - - - - - - - - - - - - - - - - //
// Pricing
// Basic price. Will be changed in at setPriceArray() function

const formOptions = [
  ['Stage', 0],
  ['Clean', 0],
  ['Full', 60],
  ['Partial', 30],
  ['Change floors', 10],
  ['Change walls', 10],
  ['Empty version', 10],
  ['Change background', 15],
  ['Other', 0],
];
const formOptionsDescription = [];
formOptionsDescription.push(
  'Add furniture, decor, plants, lights or anything else to the picture'
);
formOptionsDescription.push(
  'Remove any object, wires, decor, stains, etc. from the picture'
);
formOptionsDescription.push(
  'Area of the work is more than 40% of the picture. Example (Remove all furniture in the picture)'
);
formOptionsDescription.push(
  'Area of the work is less than 40% of the picture. Example (Remove books from the shelf, etc.)'
);
formOptionsDescription.push('Change existing floors on the picture');
formOptionsDescription.push(
  'Change existing walls on the picture. (Paint, wallpapers, wall panels etc.)'
);
formOptionsDescription.push(
  'Include version without furniture with final image'
);
formOptionsDescription.push('Change view from windows or sky in exterior shot');
formOptionsDescription.push(
  'Any other work you think needs to be done with the picture (Discussed with manager when confirming the project'
);
// Are used to create options. Also used at roomSelectedOptions variable to map selections
const roomOptions = [
  'Bedroom',
  'Living-room',
  'Bathroom',
  'Kitchen',
  'Dinning',
  'Office',
  'Kid-room',
  'Nursery',
  'Terrace',
  'Outdoor',
  'Exterior',
  'Other',
];

const formOptionsTitles = new Map();

formOptions.forEach((option, i) => {
  formOptionsTitles.set(option[0], formOptionsDescription[i]);
});

//
// For color picker
let colorTarget;

// We map through array to render color boxes
const colorLike = new Array(12);
const colordisLike = new Array(12);
colorLike.fill('');
colordisLike.fill('');

// We map through array to render image upload boxes
const uploadBoxesCount = new Array(3);
const refBoxesCount = new Array(6);
uploadBoxesCount.fill(0);
refBoxesCount.fill(0);

// Observer global
// Intersection Observer
let minId = null;
let maxId = null;
let debounceTimeout = null;

// Checks if an element is in viewport
function applyChanges() {
  const items = document.querySelectorAll('.anim');
  items.forEach((el) => {
    if (el.id >= minId && el.id <= maxId) {
      const animType = el.getAttribute('anim');
      if (animType === 'fade') {
        el.classList.add('fadeIn');
      }
      if (animType === 'up') {
        el.classList.remove('txtUp');
      }
      if (animType === 'down') {
        el.style.transform = 'translateY(200%)';
      }
      if (animType === 'left') {
        el.style.transform = 'translateX(-100%)';
      }
      if (animType === 'right') {
        el.style.transform = 'translateX(100%)';
      }
    }
  });
  minId = null;
  maxId = null;
}

function reportIntersection(entries) {
  clearTimeout(debounceTimeout);
  entries.forEach((entry) => {
    // If not removes the animation class
    if (!entry.isIntersecting) {
      const animType = entry.target.getAttribute('anim');
      if (animType === 'fade') {
        entry.target.classList.remove('fadeIn');
        return;
      }
      if (animType === 'down' || animType === 'up') {
        entry.target.classList.add('txtUp');
        return;
      }
      if (animType === 'left' || animType === 'right') {
        entry.target.style.transform = 'translateX(0%)';
        return;
      }
      return;
    } else if (entry.isIntersecting) {
      const entryId = parseInt(entry.target.id);
      if (minId === null || maxId === null) {
        minId = entryId;
        maxId = entryId;
      } else {
        minId = Math.min(minId, entryId);
        maxId = Math.max(maxId, entryId);
      }
    }
  });
  debounceTimeout = setTimeout(applyChanges, 50);
}

const contentObserver = new IntersectionObserver(reportIntersection, {
  root: null,
  rootMargin: '275px',
});

// DOM array is fixed. The first box is [0], the second box is [1], the third one is [2], etc-etc...
let arrDOM = new Array(uploadBoxesCount.length + refBoxesCount.length);
arrDOM.fill('');
// System array assigns values to the DOM array but using the push method.
// So if  you upload an image into the 2nd box, it will point to the 2nd box like this:
// arrSystem.push(arrDOM[1]) <-- uploading to the second box
// When you click the 'X' icon, it uses the 'find' method to locate the value in the system array, and then splices it.
let arrSystem = new Array(uploadBoxesCount.length + refBoxesCount.length);
arrSystem.fill('');
let imgUploadCounter = 0;

// used at setTotalPrice function, which takes all individual selections of the box and writes them into the array
const totalPrice = [];

// a map is used to record the selections
const selectedOptions = new Map();
const roomSelectedOptions = new Map();

// The size is based on the number of options
for (let i = 0; i < formOptions.length; i++) {
  selectedOptions.set(formOptions[i][0], false);
}
for (let i = 0; i < roomOptions.length; i++) {
  roomSelectedOptions.set(roomOptions[i], false);
}

// to record selections
const checkboxSelections = [];
const checkboxRoomSelections = [];
const roomSelectionsWrite = [];

// controls the number of image upload boxes
const boxesCount = {
  min: 3,
  max: 12,
};

let pickDefaultSelections = false;
// We record the selections into the global array
const initSelections = function (options) {
  // We copy info from map
  const arr = Array.from(selectedOptions, ([key, value]) => {
    return { [key]: value };
  });
  // Paste it into arr
  // The total number of arrs is based on the number of boxes
  for (let i = 0; i < uploadBoxesCount.length; i++) {
    checkboxSelections[i] = null;
    checkboxRoomSelections[i] = null;
    roomSelectionsWrite[i] = 0;
  }
};
initSelections();
let checkboxDefaultSelections = [...checkboxRoomSelections];

// The total price is based on the selections (this time added together)
let formSum = new Array(uploadBoxesCount.length);
formSum.fill(0);
let formSumTotal = 0;

// userObject is used to store user info, it's sent to Firebase
let userObject;
let userObjectE;
let userObjectA;
let imgUrl = [];

// Get the date when
const curDate = new Date();
const dateOpt = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};
const newDate = new Intl.DateTimeFormat('ru-RU', dateOpt).format(curDate);

const parseFormOptions = function (i, num) {
  const tempArr = [];
  formOptions.forEach((option, i) => {
    tempArr.push(option[1]);
  });
  return tempArr;
};
let checkboxSelected = 0;
function App() {
  // Auth
  // - - - - - - - - - - - - - - - - //
  // * * * * VARIABLES AND STATES * * * * *//
  // - - - - - - - - - - - - - - - - //
  const [isLoading, setIsLoading] = useState(0);

  //Register
  const [displayBanner, setDisplayBanner] = useState(true);
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loggedUserType, setLoggedUserType] = useState(0);
  const [loggedUser, setLoggedUser] = useState({});
  const [loginToken, setLoginToken] = useState('');
  const [token, setToken] = useState();

  // Image Upload
  const imageDisplayed = [];
  const [imageUpload, setImageUpload] = useState(null);
  const [sppStyle, setSppStyle] = useState('');
  const [uploadBoxes, setUploadBoxes] = useState(uploadBoxesCount.length);
  let checkboxClass = 'Mt__label';
  const [imageUrls, setImageUrls] = useState([]);
  const allowedExts = ['jpeg', '.jpg', '.png', '.gif'];
  // const imageListRef = {};
  const imageListRef = ref(storage, 'images/');

  // - - - - - - - - - - - - - - - - //
  // * * * * FUNCTIONS!!! * * * * //
  // - - - - - - - - - - - - - - - - //
  const doImageDisplay = function (img, boxNum, drag = false, passedFile) {
    const imgExtDrag = passedFile.name;

    const imgExt = !drag
      ? img.value.trim().toLowerCase().slice(-4)
      : imgExtDrag.trim().toLowerCase().slice(-4);
    let imgExtPass = false;

    // Check file format
    allowedExts.forEach((ext) => {
      if (imgExt === ext) {
        imgExtPass = true;
      }
    });
    // Return if !format
    if (!imgExtPass) {
      return;
    }
    const selectEl = [...img.parentElement.children];

    // Check the file size
    if (passedFile.size > 22000000) {
      selectEl.forEach((e) => {
        if (e.classList.contains('spp1-cujs')) {
          const lastel = e.lastElementChild;
          lastel.style.display = 'block';
        }
      });
      return;
    }
    selectEl.forEach((e) => {
      if (e.classList.contains('spp1-cujs')) {
        const lastel = e.lastElementChild;
        lastel.style.display = 'none';
      }
    });
    arrDOM[boxNum] = passedFile;
    arrSystem[boxNum] = passedFile;
    setImageUpload(passedFile);
    checkboxSelected = boxNum;
    const crossIcon = img.parentElement.parentElement.firstElementChild;
    const sppBtn =
      img.parentElement.parentElement.lastElementChild.lastElementChild;

    let imgObjUrl;
    // Obtain the image file
    if (drag) {
      imgObjUrl = passedFile;
    } else if (!drag) {
      imgObjUrl = img.files[0];
    }

    selectEl.forEach((el) => {
      // Disable the input
      if (el.tagName === 'INPUT') {
        el.type = '';
      }

      // Add the file to src and run the animation
      if (el.classList.contains('spp1-cloud-icon')) {
        el.firstElementChild.style.opacity = 0;
        let showImg = setTimeout(() => {
          showImg = setTimeout(() => {
            el.firstElementChild.src = URL.createObjectURL(imgObjUrl);

            el.firstElementChild.addEventListener(
              'load',
              (e) => {
                el.style.maxWidth = `100%`;
                el.style.height = `100%`;
                e.target.style.opacity = 1;
              },
              { once: true }
            );

            clearTimeout(showImg);
          }, 300);
        }, 300);
      }

      if (el.tagName === 'P') {
        el.style.display = 'none';
      }
    });
    crossIcon.style.display = 'block';

    sppBtn.classList.remove('deactivated');
    const showIcon = setTimeout(() => {
      crossIcon.style.opacity = 1;
      crossIcon.style.transform = `translateY(0)`;
      sppBtn.style.opacity = 0.9;
      sppBtn.style.transform = `translateY(0)`;
      updateCheckboxAll();

      updateSumTot();

      clearTimeout(showIcon);
    }, 500);
  };
  const handleImagesBoxes = function (num) {
    const runScripts = (status) => {
      setUploadBoxes(uploadBoxesCount.length);

      // This is required because we use 'pop' method and it reduces the length
      const boxNum =
        status === 'pop'
          ? uploadBoxesCount.length
          : uploadBoxesCount.length - 1;

      checkboxSelected = boxNum;

      // Clear checkboxselection
      updateCheckboxAll(null);

      // Clear the array
      formSum[boxNum] = 0;
      arrSystem[boxNum] = '';
      arrDOM[boxNum] = '';

      updateSumTot();
    };
    if (num === 0 && uploadBoxesCount.length > boxesCount.min) {
      uploadBoxesCount.pop();
      roomSelectionsWrite.pop();
      runScripts('pop');
    }
    if (num === 1 && uploadBoxesCount.length < boxesCount.max) {
      uploadBoxesCount.push(0);
      roomSelectionsWrite.push(0);
      runScripts();
    }
  };

  const doCloudDisplay = function (img, arrD, boxNum) {
    // loop through spp1-form-img__box to find 'label'
    const allEls = img.closest('.spp1-form-price');
    const crossIcon = img;
    const sppBtn = img.parentElement.lastElementChild.lastElementChild;
    const selectEl = [...img.parentElement.children];
    selectEl.forEach((parent) => {
      if (parent.tagName === 'LABEL') {
        // the 'label' is found!
        [...parent.children].forEach((el) => {
          // Enable the input
          if (el.tagName === 'INPUT') {
            el.type = 'file';
          }
          // loop through the 'label' and replace elements
          if (el.classList.contains('spp1-cloud-icon')) {
            el.firstElementChild.style.opacity = 0;

            // Disable the button
            sppBtn.classList.toggle('deactivated');
            // Clear the array & total $ in form
            const arr = Array.from(selectedOptions, ([key, value]) => {
              return { [key]: false };
            });
            checkboxSelected = boxNum;
            // Clear checkboxselection
            updateCheckboxAll(null);

            formSum[boxNum] = 0;
            let hideImg = setTimeout(() => {
              el.firstElementChild.src = '/upload-to-cloud.png';

              el.style.maxWidth = `62px`;
              el.style.height = `auto`;
              // sppBtn.style.transitionDuration = `0.25s`;
              let hideBtn = setTimeout(() => {
                clearTimeout(hideBtn);
              }, 900);
              hideImg = setTimeout(() => {
                el.firstElementChild.style.opacity = 1;
                clearTimeout(hideImg);
              }, 250);
            }, 500);
          }
          if (el.tagName === 'P') {
            el.style.display = 'block';
          }
        });

        // Hide the cross icon

        crossIcon.style.opacity = 0;
        crossIcon.style.transform = `translateY(20px)`;
        const hideIcon = setTimeout(() => {
          crossIcon.style.display = 'none';
          clearTimeout(hideIcon);
        }, 500);
      }
      if (parent.classList.contains('spp1-form-price')) {
        parent.firstElementChild.textContent = '';
      }
    });
    // Clear the array
    arrSystem[boxNum] = '';
    arrD[boxNum] = '';

    formSum[boxNum] = 0;

    updateSumTot();
  };

  const uploadImage = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${v4() + imageUpload.name}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  const adminRegister = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
    } catch (error) {
      console.error(error.message);
    }
  };
  const adminRefresh = function () {
    if (loggedUserType === 1385155860) {
      getUsers();
      document.querySelectorAll('.adm__card').forEach((card) => {
        card.classList.remove('fadeInUp');
      });
      setTimeout(() => {
        document.querySelectorAll('.adm__card').forEach((card) => {
          card.classList.add('fadeInUp');
        });
      }, 100);
    }
  };

  const loginUiUpdate = function (currentUser) {
    // Update UI
    const updateFieldsUI = function () {
      const headerRight = document.querySelector('.app__header--right');
      const refreshBtn = document.querySelector('.app__refreshbtn');
      refreshBtn.style.display = 'block';
      const inputFields = document.querySelectorAll('.app__log-input');
      const inputFieldsLogout = document.querySelectorAll('.app__logout-input');
      inputFields.forEach((field) => {
        field.classList.add('fadeOut');
        field.classList.remove('fadeIn');
      });
      inputFieldsLogout.forEach((field) => {
        field.classList.add('fadeIn');
        field.classList.remove('fadeOut');
      });
    };
    updateFieldsUI();
    if (currentUser !== '000') {
      setLoggedUser(currentUser);
    }
    // getUsers();
  };

  const adminLogin = async () => {
    const pathname = window.location.pathname;
    if (pathname === '/admin') {
      try {
        const user = await signInWithEmailAndPassword(
          auth,
          loginEmail,
          loginPassword
        );

        // Test

        onAuthStateChanged(auth, (currentUser) => {
          loginUiUpdate(currentUser);
          setLoggedUserType(1385155860);
          localStorage.setItem('y', auth.lastNotifiedUid);

          getUsers();
        });
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const adminLogout = async () => {
    await signOut(auth);
    // Update UI
    const updateFieldsUI = function () {
      const headerRight = document.querySelector('.app__header--right');
      const inputFields = document.querySelectorAll('.app__log-input');
      const inputFieldsLogout = document.querySelectorAll('.app__logout-input');

      inputFields.forEach((field) => {
        field.classList.add('fadeIn');
        field.classList.remove('fadeOut');
      });
      inputFieldsLogout.forEach((field) => {
        field.classList.add('fadeOut');
        field.classList.remove('fadeIn');
      });
    };
    setLoggedUser({});
    setLoggedUserType(0);
    updateFieldsUI();
  };

  // State for pricing
  const priceArray = parseFormOptions();
  const [newPrice, setNewPrice] = useState(priceArray);
  const setPriceArray = function (i, num) {
    priceArray[i] = num;
  };
  // Function for everything
  const showValidation = function () {
    const checkoutSection = document.getElementById('chckSection');
    document.body.style.overflow = 'hidden'; // hides the scroll bar
    checkoutSection.style.display = 'block';
    validateCheck();
  };

  const openCheckout = function () {
    const checkoutSection = document.getElementById('chckSection');
    const checkoutWrapper = document.querySelector('.checkout-wrapper');
    const validationWindow = document.querySelector(
      '.checkout-confirmation-pre'
    );
    document.body.style.overflow = 'hidden'; // hides the scroll bar
    checkoutSection.style.display = 'block';
    // checkoutSection.style.background = 'var(--main-light)';
    validationWindow.style.display = 'none';
    checkoutWrapper.style.display = 'block';
    checkoutWrapper.classList.add('fadeIn');

    // Display Checkout Data

    const displayCheckout = function () {
      const nameEl = document.getElementById('chckName');
      const emailEl = document.getElementById('chckEmail');
      const addressEl = document.getElementById('chckAddress');
      const colorsEl = document.getElementById('chckColors');
      colorsEl.innerHTML = '';

      const addColors = function () {
        const originalEl = document.querySelector('.spp-color-container');
        return originalEl.innerHTML;
      };
      colorsEl.insertAdjacentHTML('afterbegin', addColors());
      colorsEl.classList.add('spp-color-container');
      // colorsEl.insertAdjacentHTML(
      //   'afterbegin',

      // );
      // const descEl = document.getElementById('chckDesc');
      const styleEl = document.getElementById('chckStyle');
      const refsEl = document.querySelectorAll('#chckRefs figure');

      nameEl.textContent = newName ? newName : 'Name is missing';
      emailEl.textContent = newEmail ? newEmail : 'Email is missing';
      addressEl.textContent = newAddress ? newAddress : 'No address';
      // descEl.textContent = newDesc ? newDesc : 'No description';
      const imageEl = document.getElementById('chckImgs');
      const refEl = document.getElementById('chckRefs');
      imageEl.innerHTML = ' ';
      refEl.innerHTML = ' ';

      const mantineEditor = document.querySelector(
        '.mantine-RichTextEditor-root'
      );
      // We copy classes from the original editor to apply styles
      const mantineClasses = mantineEditor.classList;
      const descEl = document.getElementById('chckDesc');
      const addMantine = function () {
        descEl.innerHTML = '';
        mantineClasses.forEach((item) => descEl.classList.add(item));

        const editorEl = document.querySelector('.ql-editor');
        return editorEl.innerHTML;
      };
      descEl.insertAdjacentHTML('afterbegin', addMantine());

      // Functions
      const listImages = function (arr, imgUrl) {
        arr.forEach((image, i) => {
          if (image) {
            const imgUrlNew = URL.createObjectURL(image);
            imgUrl[i] = imgUrlNew;
          }
        });
        return imgUrl;
      };

      const showEmptyImages = function (imageEl) {
        const thecode = `
                <figure>
                  <div class="checkout-img"></div>
                  <figcaption>
                  <h5 class="checkout-img-price deactivated">-</h5>
                  </figcaption>
                </figure>`;
        for (let i = 0; i < 6; i++) {
          imageEl.insertAdjacentHTML('beforeend', thecode);
        }
      };
      // Clear the data

      const displayImages = function (_imageEl) {
        const state = _imageEl.id === 'chckImgs' ? true : '';

        // Create temp array and fill it with our images
        const imgUrlAll = new Array(arrSystem.length);
        imgUrlAll.fill('');
        listImages(arrSystem, imgUrlAll);

        // Gallery style is based on the number of images
        let imgUrlCount = 0;
        // For each image in the temp array
        const appendImg = (image, i, imgEl) => {
          imgUrlCount++;
          // We append child
          const thecode = `
                <figure>
                  <img src="${image}" class="checkout-img"></img>
                  <figcaption>
                  ${
                    state &&
                    `<h5 class="checkout-img-price">($${formSum[i]})</h5>`
                  }
                    
                  </figcaption>
                </figure>`;
          imgEl.insertAdjacentHTML('beforeend', thecode);
        };
        imgUrlAll.forEach((image, i) => {
          if (image === '') {
            return;
          }

          if (state && i < uploadBoxesCount.length) {
            appendImg(image, i, _imageEl);
          }
          if (state === '' && i >= uploadBoxesCount.length) {
            appendImg(image, i, _imageEl);
          }

          // imagesEl[
          //   i
          // ].lastElementChild.lastElementChild.textContent = `(11$${formSum[i]})`;
        });
        const mq = window.matchMedia('(min-width: 999px)');

        if (imgUrlCount === 0 && mq.matches) {
          showEmptyImages(_imageEl);
        }
      };

      displayImages(imageEl);
      displayImages(refEl);
    };

    displayCheckout();
  };

  const closeValidation = function () {
    const checkoutSection = document.getElementById('chckSection');
    document.body.style.overflow = 'auto'; // hides the scroll bar
    checkoutSection.style.display = 'none';
    const validationWrapper = document.getElementById('chckValidation');
    validationWrapper.style.display = 'none';
  };

  const closeFin = function () {
    const progressTitle = document.querySelector('.order-loader-title');
    const text = progressTitle.firstChild.textContent;
    window.location.href = 'https://prelab.design/';
    // if (text === 'An error occured' || text === 'New order submitted!')
  };

  const completeOrder = function () {
    // Show loader
    const windowElementWrapper = document.getElementById('chckLoader');
    const windowElement = document.querySelector('.complete-order-loader');
    const progressText = document.querySelector('.order-loader-subtitle');
    const progressTitle = document.querySelector('.order-loader-title');
    const progressTimer = document.querySelector('.order-loader-timer');
    const progressRing = document.querySelector('.order-loader-text');
    const progressCross = document.querySelector('.order-loader-cross');
    windowElementWrapper.style.display = 'block';
    windowElement.style.display = 'flex';
    windowElement.classList.add('fadeIn');
    progressText.firstChild.textContent = 'Init';
    if (arrSystem == null)
      // If array is empty return
      return;

    // Recording colors picks
    const newLikesArr = [];
    const newdisLikesArr = [];
    // Get the colors selected
    progressText.firstChild.textContent = 'Getting data';
    for (let i = 0; i < colordisLike.length; i++) {
      const val = colordisLike[i];
      if (val) {
        newdisLikesArr.push(val);
      }
    }
    for (let i = 0; i < colorLike.length; i++) {
      const val = colorLike[i];
      if (val) {
        newLikesArr.push(val);
      }
    }
    const newLikes = newLikesArr.join(', ');
    const newdisLikes = newdisLikesArr.join(', ');
    if (!loggedUser.lastNotifiedUid) {
      console.error('User error. Please contact support@prelab.design');
      progressRing.style.opacity = 0;
      progressText.style.opacity = 1;
      progressText.textContent = `Please contact support@prelab.design`;
      progressTitle.style.transform = `translateY(-50px)`;
      progressTitle.firstChild.textContent = 'An error occured';
      progressCross.style.display = 'block';
      return;
    }
    // We create new user here
    userObject = {
      accID: loggedUser.lastNotifiedUid,
      date: newDate,
      promo: newPromo,
      name: newName,
      email: newEmail,
      subbednews: newSub,
      address: newAddress,
      style: sppStyle,
      desc: newDesc,
      sum: `$${formSumTotal}`,
      colorLike: newLikes,
      colorDislike: newdisLikes,
    };
    // We create new email here
    // userObjectA = {
    //   to: ['prelab.space@gmail.com'],
    //   message: {
    //     subject: 'New order',
    //     html: `A new order was submitted on your website. <br>
    //     Please visit the admin panel to see the order details. <br>
    //     <a href="https://prelab.design/admin/">https://prelab.design/admin/</a>`,
    //   }
    // };
    // For the user
    userObjectE = {
      to: [newEmail, 'info@prelab.design'],
      message: {
        subject: 'Order received - Prelab.design',
        text: `Hello ${userObject.name},
        Thank you for making a new order on our website.
        Our team will contact you shortly. 
        Best regards,
        Prelab Team`,
        html: `Hello ${userObject.name}, <br>
        <br>
        Thank you for making a new order on our website. <br>
        Our team will contact you shortly. <br>
        <br>
        Best regards, <br>
        Prelab Team`,
      },
    };
    // Checkboxes selections
    let arr;
    let attemptsCount = 0;
    progressText.firstChild.textContent = 'Analysing selections';
    for (let i = 0; i < uploadBoxesCount.length; i++) {
      let selectionsStr = '';
      let roomselectionsStr = '';
      // Room price options
      if (checkboxSelections[i] === null) {
        arr = '';
      } else {
        checkboxSelections[i].map((item) => {
          const str = JSON.stringify(item);
          selectionsStr += str;
        });
      }
      // Room type options
      if (checkboxRoomSelections[i] === null) {
        arr = '';
      } else {
        checkboxRoomSelections[i].map((item) => {
          const str = JSON.stringify(item);
          roomselectionsStr += str;
        });
      }
      // Result
      userObject[`selected${i + 1}`] = selectionsStr;
      userObject[`roomselected${i + 1}`] = roomselectionsStr;
    }

    // Define the file upload folder

    let newUserCreated = false;
    let createTimer = setTimeout(() => {
      checkCreateStatus();
    }, 5000);
    const checkCreateStatus = function () {
      if (imgUploadCounter < arrSystem.length) {
        attemptsCount = attemptsCount + 1;
        if (attemptsCount < 2)
          progressText.firstChild.textContent = `Still processing...`;
        if (attemptsCount >= 2) {
          userObject.error = 'Image number error';
          progressText.firstChild.textContent = `Still processing 2...`;
        }
        if (attemptsCount >= 4) {
          userObject.error = 'Image number error';
          progressText.firstChild.textContent = `Still processing 3...`;
        }
        if (attemptsCount >= 6) {
          userObject.error = 'Image number error';
          progressText.firstChild.textContent = `Connection error. Please try again later.`;
        }

        clearTimeout(createTimer);
        createTimer = setTimeout(checkCreateStatus(), 5000);
      } else {
        if (newUserCreated) {
          progressTitle.firstChild.textContent = 'New order submitted!';
          progressTitle.style.transform = `translateY(-50px)`;
          progressText.style.opacity = 1;
          progressText.textContent = `Thank you, our manager will contact you soon`;

          window.sessionStorage.clear();
          progressTimer.textContent = `You may close this window now`;
          progressCross.style.display = 'block';
          // progressCross.style.classList.add('fadeIn');
          clearTimeout(createTimer);
        } else {
          clearTimeout(createTimer);
          // No images
          createUser();
          createEmail();
          window.sessionStorage.clear();
          progressRing.style.opacity = 0;
          progressText.style.opacity = 1;
          progressText.textContent = `Thank you, our manager will contact you soon`;
          progressTimer.textContent = `You may close this window now`;
          progressTitle.style.transform = `translateY(-50px)`;
          progressTitle.firstChild.textContent = 'New order submitted!';
          progressCross.style.display = 'block';
          // progressCross.style.classList.add('fadeIn');
        }
      }
    };
    const uploadFolderId = v4();
    const userFolder = `images/${userObject.accID}`;

    for (let i = 0; i < arrSystem.length; i++) {
      // Define file location
      // If array has image > upload it
      progressText.firstChild.textContent = `Uploading image(s)`;
      const imageRef = ref(storage, `${userFolder}/${arrSystem[i].name}`);
      if (arrSystem[i] === '') {
        userObject[`${i < uploadBoxesCount.length ? 'img' : 'ref'}${i + 1}`] =
          '';
        imgUploadCounter++;
      } else {
        uploadBytes(imageRef, arrSystem[i]).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            imgUrl[i] = url.toString();
            userObject[
              `${i < uploadBoxesCount.length ? 'img' : 'ref'}${i + 1}`
            ] = imgUrl[i];
            imgUploadCounter++;

            if (imgUploadCounter === arrSystem.length) {
              newUserCreated = true;
              createUser();
              createEmail();
              window.sessionStorage.clear();
              progressRing.style.opacity = 0;
              progressText.style.opacity = 1;
              progressText.textContent = `Thank you, our manager will contact you soon`;
              progressTimer.textContent = `You may close this window now`;
              progressTitle.style.transform = `translateY(-50px)`;
              progressTitle.firstChild.textContent = 'New order submitted!';
              progressCross.style.display = 'block';
            }
          });
        });
      }

      // We use counter to count number of images uploaded
      // Once everything is uploaded > CreateUser();
    }
  };

  const updateRoomForm = function (e) {
    document.querySelectorAll('.Mt__checkboxroom').forEach((checkbox, i) => {
      // Toggles the checkmark
      if (checkbox.classList.contains('active')) {
        if (roomSelectedOptions.get(roomOptions[i]) === false) {
          roomSelectedOptions.set(roomOptions[i], true);
        }
      } else {
        roomSelectedOptions.set(roomOptions[i], false);
      }
    });
  };

  const updatePricingForm = function (e) {
    document.querySelectorAll('.Mt__checkboxprice').forEach((checkbox, i) => {
      // Toggles the checkmark
      if (checkbox.classList.contains('active')) {
        totalPrice[i] = newPrice[i];
        if (selectedOptions.get(formOptions[i][0]) === false) {
          selectedOptions.set(formOptions[i][0], true);
        }
      } else {
        totalPrice[i] = 0;
        selectedOptions.set(formOptions[i][0], false);
      }
    });
    //Check

    disabledBoxes();
    selectedPricing();
    // document.querySelector(
    //   '.Mt__totalcost--all'
    // ).textContent = `$${formSumTotal}`;
  };

  const updateSumTot = (disc = 1) => {
    formSumTotal = formSum.reduce((acc, cur) => acc + cur, 0);

    //@todo if promocode is enterred, give discount
    formSumTotal *= disc;
    if (disc === 0) formSumTotal = 0;
    const sumTot = document.querySelector('.spp1-form-totsum');
    const chckTot = document.getElementById('chckTotal');
    sumTot.firstElementChild.textContent = `Total Price: $${formSumTotal}`;
    chckTot.textContent = `$${formSumTotal} ${
      disc === 1 ? '' : `(${100 - 100 * disc}% off)`
    }`;
  };

  const updateCheckboxAll = (state = true) => {
    // if (!state) {
    //   return (checkboxSelections[checkboxSelected] = null);
    // }
    // We record the selections into the global array
    if (state) {
      const arr = Array.from(selectedOptions, ([key, value]) => {
        return { [key]: value };
      });
      checkboxSelections[checkboxSelected] = arr;

      const arr2 = Array.from(roomSelectedOptions, ([key, value]) => {
        return { [key]: value };
      });
      checkboxRoomSelections[checkboxSelected] = arr2;
    } else if (!state) {
      checkboxSelections[checkboxSelected] = null;
      checkboxRoomSelections[checkboxSelected] = null;
    }
  };
  // Boxes
  const toggleBoxes = function (num, state, onetap = false) {
    document.querySelectorAll('.Mt__checkboxprice').forEach((checkbox, i) => {
      if (!onetap) {
        if (state) {
          if (i <= num) {
            const gridEl = checkbox.closest('.Mt__label');

            gridEl.classList.remove('deactivated');
          }
        }
        if (!state) {
          if (i > num) {
            const gridEl = checkbox.closest('.Mt__label');

            gridEl.classList.add('deactivated');
          }
        }
      }
      if (onetap && i === num) {
        const gridEl = checkbox.closest('.Mt__label');
        gridEl.classList.add('deactivated');
      }
    });
  };
  const disabledBoxes = function () {
    // Boxes Act: First two

    toggleBoxes(3, true);
    if (
      selectedOptions.get(formOptions[0][0]) ||
      selectedOptions.get(formOptions[1][0])
    ) {
      // toggleBoxes(3, true);
      if (selectedOptions.get(formOptions[2][0])) {
        toggleBoxes(formOptions.length, true);
        toggleBoxes(3, true, true);
      } else if (selectedOptions.get(formOptions[3][0])) {
        toggleBoxes(formOptions.length, true);
        toggleBoxes(2, true, true);
      } else {
        toggleBoxes(formOptions.length - 6, false);
        if (
          selectedOptions.get(formOptions[4][0]) ||
          selectedOptions.get(formOptions[5][0]) ||
          selectedOptions.get(formOptions[6][0]) ||
          selectedOptions.get(formOptions[7][0])
        ) {
          for (let i = 0; i < formOptions.length; i++) {
            setPriceArray(i, 0);
            selectedOptions.set(formOptions[i][0], false);
            // checkboxes[i].classList.remove('active');
            priceArray[i] = 0;
            totalPrice[i] = 0;
          }
        }
      }
    } else {
      toggleBoxes(formOptions.length - 8, false);
      const checkboxes = document.querySelectorAll('.Mt__checkboxprice');
      for (let i = 0; i < formOptions.length; i++) {
        setPriceArray(i, 0);
        selectedOptions.set(formOptions[i][0], false);
        // checkboxes[i].classList.remove('active');
        priceArray[i] = 0;
        totalPrice[i] = 0;
      }
    }
  };
  const setTotalPrice = function () {
    document.querySelectorAll('.Mt__checkboxprice').forEach((checkbox, i) => {
      // Toggles the checkmark
      if (checkbox.classList.contains('active')) {
        if (priceArray[i] === 999) {
          totalPrice[i] = 0;
        } else {
          totalPrice[i] = priceArray[i];
        }
        // totalPrice[i] = newPrice[i];
        if (selectedOptions.get(formOptions[i][0]) === false) {
          selectedOptions.set(formOptions[i][0], true);
        }
      }
    });
  };
  // Pricing update
  const selectedPricing = function () {
    if (
      selectedOptions.get(formOptions[0][0]) &&
      selectedOptions.get(formOptions[1][0])
    ) {
      setPriceArray(0, 0);
      setPriceArray(1, 0);
      setPriceArray(2, 100);
      setPriceArray(3, 50);
      setPriceArray(4, 999);
      setPriceArray(5, 999);
      setPriceArray(6, 10);
      setPriceArray(7, 15);
      setPriceArray(8, 0);
      if (selectedOptions.get(formOptions[3][0])) {
        // Change walls & change floors $10 each
        setPriceArray(4, 10);
        setPriceArray(5, 10);
      } else if (selectedOptions.get(formOptions[4][0])) {
        // Change walls & change floors free
        setPriceArray(4, 999);
        setPriceArray(5, 999);
      }
    } else if (selectedOptions.get(formOptions[0][0])) {
      setPriceArray(0, 0);
      setPriceArray(1, 0);
      setPriceArray(2, 65);
      setPriceArray(3, 30);
      setPriceArray(4, 10);
      setPriceArray(5, 10);
      setPriceArray(6, 10);
      setPriceArray(7, 15);
      setPriceArray(8, 0);
    } else if (selectedOptions.get(formOptions[1][0])) {
      setPriceArray(0, 0);
      setPriceArray(1, 0);
      setPriceArray(2, 40);
      setPriceArray(3, 25);
      setPriceArray(4, 25);
      setPriceArray(5, 25);
      setPriceArray(6, 999);
      setPriceArray(7, 15);
      setPriceArray(8, 0);
    } else {
    }
    setNewPrice(priceArray);
    setTotalPrice();
    // formSum[checkboxSelected] = totalPrice.reduce(
    //   (acc, value) => acc + value,
    //   0
    // );
    formSum[checkboxSelected] = 25;
    document.querySelector(
      '.Mt__totalcost'
    ).textContent = `$${formSum[checkboxSelected]}`;
  };

  const closePricingFormAdmin = function () {
    document.body.style.overflow = 'auto'; // hides the scroll bar
    const roomForm = document.querySelector('.box-selection-wrapper');
    roomForm.style.display = 'none';
  };
  const closePricingForm = function () {
    document.body.style.overflow = 'auto'; // hides the scroll bar
    // We record the selections into the global array
    updateCheckboxAll();

    // Then we empty the selections from the map
    for (let i = 0; i < formOptions.length; i++) {
      selectedOptions.set(formOptions[i][0], false);
    }
    // Then we empty the selections from the map
    for (let i = 0; i < roomOptions.length; i++) {
      roomSelectedOptions.set(roomOptions[i], false);
    }

    updateSumTot();

    const roomForm = document.querySelector('.box-selection-wrapper');
    roomForm.style.display = 'none';
    roomSelectionsWrite[checkboxSelected] = 1;
  };
  const openPricingForm = function (num, admin = false, _u, _z) {
    // Functions
    document.body.style.overflow = 'hidden'; // hides the scroll bar
    checkboxSelected = num;
    if (admin) {
      const getRmType = function () {
        const newArr = _u
          .replaceAll('false', '')
          .replaceAll('{', '')
          .replaceAll('}', '')
          .replaceAll('"', '')
          .replaceAll('"', '')
          .replaceAll('true', '')
          .split(':');
        const newArrValues = _u
          .replaceAll('Bedroom', '')
          .replaceAll('{', '')
          .replaceAll('}', '')
          .replaceAll('"', '')
          .replaceAll('"', '')
          .replaceAll('Bathroom', '')
          .replaceAll('Nursery', '')
          .replaceAll('Terrace', '')
          .replaceAll('Outdoor', '')
          .replaceAll('Exterior', '')
          .replaceAll('Other', '')
          .replaceAll('Living-room', '')
          .replaceAll('Kitchen', '')
          .replaceAll('Dinning', '')
          .replaceAll('Office', '')
          .replaceAll('Kid-room', '')
          .split(':');
        newArr.pop();
        newArrValues.shift();
        const rmTypeArr = [];
        let rmType = {};
        for (let i = 0; i < newArr.length; i++) {
          rmType = {};
          const boolValues = newArrValues[i] == 'true';
          rmType[`${newArr[i]}`] = boolValues;
          rmTypeArr.push(rmType);
        }
        return rmTypeArr;
      };
      // RoomPricing
      const getRmPricing = () => {
        const newArr = _z
          .replaceAll('false', '')
          .replaceAll('{', '')
          .replaceAll('}', '')
          .replaceAll('"', '')
          .replaceAll('"', '')
          .replaceAll('true', '')
          .split(':');

        const newArrValues = _z
          .replaceAll('Stage', '')
          .replaceAll('{', '')
          .replaceAll('}', '')
          .replaceAll('"', '')
          .replaceAll('"', '')
          .replaceAll('Clean', '')
          .replaceAll('Full', '')
          .replaceAll('Partial', '')
          .replaceAll('Change floors', '')
          .replaceAll('Change walls', '')
          .replaceAll('Other', '')
          .replaceAll('Empty version', '')
          .replaceAll('Change background', '')
          .split(':');
        newArr.pop();
        newArrValues.shift();
        const rmTypeArr = [];
        let rmType = {};
        for (let i = 0; i < newArr.length; i++) {
          rmType = {};
          const boolValues = newArrValues[i] == 'true';
          rmType[`${newArr[i]}`] = boolValues;
          rmTypeArr.push(rmType);
        }

        return rmTypeArr;
      };
      checkboxRoomSelections[checkboxSelected] = getRmType();

      checkboxSelections[checkboxSelected] = getRmPricing();
    }
    const roomForm = document.querySelector('.box-selection-wrapper');
    roomForm.style.display = 'block';

    // Parses all checkboxes
    const checkboxes = document.querySelectorAll('.Mt__checkboxprice');

    let tempI = 0;
    checkboxSelections[checkboxSelected].forEach((values, key) => {
      const vals = Object.values(values);

      // Checks the MAP value
      if (vals[0]) {
        checkboxes[tempI].classList.add('active');
        selectedOptions.set(formOptions[key][0], true);
      } else {
        checkboxes[tempI].classList.remove('active');
      }

      // Index
      tempI++;
    });
    tempI = 0;

    // If this is the 1st write and we pick Default Selections
    if (pickDefaultSelections && roomSelectionsWrite[checkboxSelected] === 0) {
      roomSelectionsWrite[checkboxSelected] = 1;
      checkboxRoomSelections[checkboxSelected] = [...checkboxDefaultSelections];
    }
    const roomCheckboxes = document.querySelectorAll('.Mt__checkboxroom');

    const writeRoom = (bxs) => {
      bxs.forEach((values, key) => {
        const vals = Object.values(values);

        // Checks the MAP value
        if (vals[0]) {
          if (!roomCheckboxes[tempI].classList.contains('active')) {
            roomCheckboxes[tempI].classList.add('active');
          }

          roomSelectedOptions.set(roomOptions[key], true);
        } else {
          if (roomCheckboxes[tempI].classList.contains('active')) {
            roomCheckboxes[tempI].classList.remove('active');
          }
        }

        // Index
        tempI++;
      });
    };
    writeRoom(checkboxRoomSelections[checkboxSelected]);

    document.querySelector(
      '.Mt__totalcost'
    ).textContent = `$${formSum[checkboxSelected]}`;
    updatePricingForm();
  };

  // Color Picker
  const [color, setColor] = useState('rgba(255,255,255,1)');
  const handleStyleSelect = function (e) {
    e.target.parentElement.parentElement.open = false;
    setSppStyle(e.target.title);
    window.sessionStorage.setItem('s', sppStyle);
  };
  const handleColor = function () {
    colorTarget.style.background = color;

    if (colorTarget.classList.contains('spp-color-box__dislike')) {
      colordisLike[colorTarget.getAttribute('cbox')] = color;
    } else if (colorTarget.classList.contains('spp-color-box__like')) {
      colorLike[colorTarget.getAttribute('cbox')] = color;
    }
  };
  const showPicker = function () {
    const sppColorPicker = document.querySelector('.chrome-picker');
    const hiddenBg = document.querySelector('.color-picker-bg');
    sppColorPicker.style.display = 'block';
    sppColorPicker.style.opacity = 1;
    const targetX = colorTarget.getBoundingClientRect().left;
    const targetY = colorTarget.getBoundingClientRect().top;
    const windowX = window.scrollX + targetX;
    const windowY = window.scrollY + targetY;
    sppColorPicker.style.transform = `translate(${windowX}px, ${windowY}px)`;
    hiddenBg.style.display = 'block';
    hiddenBg.addEventListener('click', function (e) {
      hiddenBg.style.display = 'none';
      sppColorPicker.style.display = 'none';
      sppColorPicker.style.opacity = 0;
      colorTarget = '';
    });
  };

  const debugScript = function (num) {
    // getUsers();
  };
  // Database
  // Create States for form
  const [actualUser, setActualUser] = useState([]);
  const [newName, setNewName] = useState('');
  const [newPromo, setNewPromo] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newFile, setNewFile] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newDesc, setNewDesc] = useState('');
  const [newSub, setNewSub] = useState(false);
  const [newTerms, setNewTerms] = useState(true);
  // const [newEmail, setNewEmail] = useState(0);

  // Create States for db users
  const [users, setUsers] = useState([]);

  const pathnamae = window.location.pathname;
  const usersCollectionRef = collection(db, 'users');

  const validateCheck = function () {
    const checkoutWrapper = document.querySelector('.checkout-wrapper');

    // checkoutSection.style.background = 'var(--main-light)';

    checkoutWrapper.style.display = 'none';

    const validationWrapper = document.getElementById('chckValidation');
    validationWrapper.style.display = 'block';
    validationWrapper.classList.add('fadeIn');
    const validationWindow = document.querySelector(
      '.checkout-confirmation-pre'
    );
    validationWindow.style.display = 'flex';

    // Select
    let issuesCounter = 0;
    const headerText = document.querySelector(
      '.checkout-confirmation-pre__top--header'
    );

    // Functions
    const listImages = function (arr, imgUrl) {
      arr.forEach((image, i) => {
        if (image) {
          const imgUrlNew = URL.createObjectURL(image);
          imgUrl[i] = imgUrlNew;
        }
      });
      return imgUrl;
    };

    // To parse image arr
    const arrayMatches = function (
      arrMain,
      arrResponse,
      property,
      propertyResponse1,
      propertyResponse2
    ) {
      arrMain.every((item, i) => {
        if (item) {
          // If there at least 1 image
          listImages(arrMain, arrResponse);
          // delete valResponse[property];
          if (i < arrMain.length / 2) {
            // images from the first half of the list
            delete valResponse[propertyResponse1];
          }
          if (i >= arrMain.length / 2) {
            // images from the second half of the list
            delete valResponse[propertyResponse2];
          }
          delete valResponse[propertyResponse2];
          return true;
        } else {
          delete valResponse[propertyResponse2];
          // If there's NO image (does nothing)

          return true;
        }
      });
    };

    //  To generate the list
    let valBool = true;

    const generateErrorList = function () {
      let newString = '';
      Object.keys(valResponse).forEach((k, i) => {
        const pickIcon = () => {
          // Check if it's critical, this only works because they have the same order
          if (
            valResponse[k] === valErrorMessages[0] ||
            valResponse[k] === valErrorMessages[1]
          ) {
            valBool = false;
            return `cross.svg`;
          } else {
            return `caution-circle.svg`;
          }
        };

        const getRespose = function () {
          if (
            valResponse[k] === valErrorMessages[0] ||
            valResponse[k] === valErrorMessages[1] ||
            valResponse[k] === valErrorMessages[2]
          )
            return valHref[0];
          if (valResponse[k] === valErrorMessages[3]) return valHref[3];
          if (valResponse[k] === valErrorMessages[4]) return valHref[4];
          if (valResponse[k] === valErrorMessages[5]) return valHref[5];
        };

        const linkHref = getRespose();
        const icon = pickIcon();
        // onClick={(e) => {
        //   e.preventDefault();
        //   document
        //     .getElementById(linkHref)
        //     .scrollIntoView({ behavior: 'smooth' });
        // }}
        let responseColor = 'var(--main-gray)';
        if (
          valResponse[k] === valErrorMessages[0] ||
          valResponse[k] === valErrorMessages[1]
        ) {
          responseColor = 'color:rgb(200,34,0)';
        } else {
          responseColor = 'color:var(--main-gray)';
        }
        newString += `<li>
                <img src="/${icon}"></img>
                <h5 style=${responseColor}>${valResponse[k]}</h5>
                <a href="#${linkHref}" ><img src="/scrollto.svg" alt="scroll-to"></a>
              </li>`;
      });

      return newString;
    };
    // Init Response obj
    const valResponse = {};
    const valErrorMessages = [
      'The name is missing',
      'The email is NOT correct',
      `You haven't enterred the address`,
      `Property images missing`,
      `No references attached`,
      `Description is empty`,
    ];
    const valHref = [
      'spp-link-txt',
      'spp-link-txt',
      `spp-link-txt`,
      `spp-link-img`,
      `spp-link-ref`,
      `spp-link-ref`,
    ];
    const valErrorMessagesCritical = [valErrorMessages[0], valErrorMessages[1]];

    // We set to false unless you have uploaded at least 1 room image

    // Check Name field
    if (newName === '') {
      valResponse['NameResponse'] = valErrorMessages[0];
      issuesCounter++;
    } else {
      delete valResponse['NameResponse'];
    }

    // Check Email field
    if (newEmail === '') {
      valResponse['EmailResponse'] = valErrorMessages[1];
      issuesCounter++;
    } else {
      delete valResponse['EmailResponse'];
    }

    // Check Address field
    if (newAddress === '') {
      valResponse['AddressResponse'] = valErrorMessages[2];
      issuesCounter++;
    } else {
      delete valResponse['AddressResponse'];
    }
    // Check Images & References
    valResponse['ImagesImgResponse'] = valErrorMessages[3];
    valResponse['ImagesRefResponse'] = valErrorMessages[4];

    // Create array with all URLs to display them
    const imgUrlAll = new Array(arrSystem.length);
    imgUrlAll.fill('');
    arrayMatches(
      arrSystem,
      imgUrlAll,
      'Images',
      'ImagesImgResponse',
      'ImagesRefResponse'
    );

    // Check Description
    if (newDesc === '' || newDesc === '<p><br></p>') {
      valResponse['DescriptionResponse'] = valErrorMessages[5];
      issuesCounter++;
    } else {
      delete valResponse['DescriptionResponse'];
    }
    // Check Colors

    headerText.textContent = `Found ${Object.keys(valResponse).length} issues`;
    if (Object.keys(valResponse).length <= 0) {
      completeOrder();
    }
    // Write
    const errorList = document.querySelector('.checkout-caution');
    const newList = generateErrorList();
    errorList.innerHTML = '';
    errorList.insertAdjacentHTML('afterbegin', newList);

    // Disable Button (if needed)
    if (valBool) {
      document
        .querySelector('.checkout-confirmation-pre__bot')
        .lastElementChild.classList.remove('deactivated');
    } else {
      document
        .querySelector('.checkout-confirmation-pre__bot')
        .lastElementChild.classList.add('deactivated');
    }
    // return valResponse;
  };
  // const list = validateCheck();

  // const [valBool, valResponse] = list;
  //
  const normalTxtColor = 'rgb(255,255,255)';
  const normalLabelColor = 'rgb(255,255,255,0.8)';
  const errorTxtColor = 'rgb(200,34,0)';
  const handlePromoApply = function (event) {
    const promofield = document.querySelector('.checkout-promo');
    console.log(promofield.value);
    const val = promofield.value.toUpperCase();
    // FREESTART2022
    if (val == 'HALLOWEEN') {
      setNewPromo('HALLOWEEN');
      updateSumTot(0.34);
    } else {
      setNewPromo('');
      updateSumTot();
    }
  };
  const handlePromoValue = function (event) {
    let enterredVal = event.target.value.replace(/[^a-zA-Z ][1-9][0]/g, '');
    event.target.value = enterredVal;
    if (enterredVal.length > 13) {
      const chars = Number(enterredVal.length) - 13;
      enterredVal = enterredVal.slice(0, -chars);
      event.target.value = enterredVal;
    }
  };
  const handleNewName = function (event) {
    let enterredVal = event.target.value.replace(/[^a-zA-Z ]/g, '');
    event.target.value = enterredVal;
    if (enterredVal.length > 30) {
      const chars = Number(enterredVal.length) - 30;
      enterredVal = enterredVal.slice(0, -chars);
      event.target.value = enterredVal;
    }
    if (event.target.value === '') {
      event.target.style.borderBottomColor = normalTxtColor;
      // Label
      event.target.parentElement.lastElementChild.style.color =
        normalLabelColor;
      setNewName('');
      return;
    }
    if (enterredVal[0] === ' ') {
      enterredVal = enterredVal.slice(1);
      event.target.value = enterredVal;
    }
    if (event.target.value.trim() === '') {
      event.target.style.borderBottomColor = errorTxtColor;
      event.target.parentElement.lastElementChild.style.color = errorTxtColor;
      if (enterredVal[0] === ' ') {
        enterredVal = enterredVal.slice(1);
        event.target.value = enterredVal;
        event.target.style.borderBottomColor = normalTxtColor;
        // Label
        event.target.parentElement.lastElementChild.style.color =
          normalLabelColor;
      }
      setNewName('');
      return;
    }
    if (enterredVal.trim().length < 3) {
      event.target.style.borderBottomColor = errorTxtColor;
      event.target.parentElement.lastElementChild.style.color = errorTxtColor;
      setNewName('');
    } else {
      if (event.target.value.trim().length >= 3) {
        // All good
        event.target.style.borderBottomColor = normalTxtColor;

        // Label
        event.target.parentElement.lastElementChild.style.color =
          normalLabelColor;
        setNewName(event.target.value);
      }
    }
    window.sessionStorage.setItem('n', event.target.value);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleEmailChange = function (event, func) {
    let enterredVal = event.target.value;
    if (enterredVal.length > 30) {
      const chars = Number(enterredVal.length) - 30;
      enterredVal = enterredVal.slice(0, -chars);
      event.target.value = enterredVal;
    }
    if (enterredVal === '') {
      event.target.style.borderBottomColor = normalTxtColor;
      // Label
      event.target.parentElement.lastElementChild.style.color =
        normalLabelColor;
      func('');
      return;
    }
    if (enterredVal[0] === ' ') {
      enterredVal = enterredVal.slice(1);
      event.target.value = enterredVal;
    }
    const data = validateEmail(enterredVal);
    if (data) {
      func(data.input);
      event.target.style.borderBottomColor = normalTxtColor;
      // Label
      event.target.parentElement.lastElementChild.style.color =
        normalLabelColor;
    } else {
      func('');
      event.target.style.borderBottomColor = errorTxtColor;
      // Label
      event.target.parentElement.lastElementChild.style.color = errorTxtColor;
    }
    window.sessionStorage.setItem('e', event.target.value);
  };

  const handleAddressChange = function (event) {
    let enterredVal = event.target.value;
    if (enterredVal.length > 50) {
      const chars = Number(enterredVal.length) - 30;
      enterredVal = enterredVal.slice(0, -chars);
      event.target.value = enterredVal;
    }
    if (enterredVal[0] === ' ') {
      enterredVal = enterredVal.slice(1);
      event.target.value = enterredVal;
    }
    setNewAddress(enterredVal);
    window.sessionStorage.setItem('a', enterredVal);
  };

  // for db
  const getUsers = async () => {
    try {
      const data = await getDocs(usersCollectionRef);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error(error);
    }
  };

  const createUser = async () => {
    // await addDoc(usersCollectionRef, userObject);
    await setDoc(doc(db, 'users', userObject.accID), userObject);
  };
  const createEmail = async () => {
    // emails
    // await setDoc(doc(db, 'mail', userObject.accID), userObjectA);
    await setDoc(doc(db, 'mail', userObject.accID), userObjectE);
    console.log('email created');
  }; // const updateUser = async (id, age) => {
  //   const userDoc = doc(db, 'users', id);
  //   const newFields = { age: age + 1 };
  //   await updateDoc(userDoc, newFields);
  // };

  const deleteUser = async (id) => {
    const userDoc = doc(db, 'users', id);
    const data = await getDocs(usersCollectionRef);

    const userD = data.docs.map((doc) => {
      if (doc.id === userDoc.id) {
        return { ...doc.data() };
      }
    });
    function removeUndefinedFromArray(arr) {
      return arr.filter((el) => el !== undefined);
    }

    const [userDcleared] = removeUndefinedFromArray(userD);

    // we found accID

    // now we need to access folder using this accID
    const storage = getStorage();
    const listRef = ref(storage, `images/${userDcleared.accID}`);
    // const listRef = ref(storage, 'images/');
    listAll(listRef)
      .then((res) => {
        // res.prefixes.forEach((folderRef) => {
        //   console.log(folderRef);
        // });
        res.items.forEach((itemRef) => {
          deleteObject(itemRef)
            .then(() => {
              console.log('deleted');
            })
            .catch((err) => console.error(err));
          // All the items under listRef.
        });
      })
      .catch((err) => {
        console.error(err);
      });
    // deletes user
    await deleteDoc(userDoc);
    // updates all users and updates UI
    adminRefresh();
  };

  const [countertest, setcounterTest] = useState(0);
  // const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //     return;
  //   }

  //   const token = await executeRecaptcha('yourAction');
  //   // Do whatever you want with the token
  // }, [executeRecaptcha]);

  useEffect(() => {
    const playStartAnimation = () => {
      setIsLoading(1);

      // Shows reveal animation
      function revealContent() {
        const contLoader = document.getElementById('content-loader');
        // remove loader after 1s
        setTimeout((id) => {
          contLoader.style.display = 'none';
          clearTimeout(id);
        }, 1000);

        // Number of layers
        let revealerCount = 4;

        const revealer = document.querySelector('.revealer');
        revealer.style.display = 'block';

        const revealerItems = document.querySelectorAll('.revealer__layer');
        document.querySelector('.App').classList.add('fadeInTop');
        const revealEnd = () => {
          if (revealerCount === 0) {
            revealer.style.display = 'none';
          }
        };

        revealerItems.forEach((item) => {
          item.classList.add('anim--reveal');
          item.addEventListener(
            'animationend',
            () => {
              item.classList.remove('anim--reveal');
              revealerCount--;
              revealEnd();
            },
            true
          );
        });
      }
      revealContent();
    };
    document.fonts.ready.then(() => {
      playStartAnimation();
    });
    // const loadFont = setInterval(function () {
    //   console.log('tick');
    //   if (document && document.fonts) {
    //     // @todo
    //     // change this function into promise
    //     if (document.fonts.status === 'loaded') {
    //       playStartAnimation();
    //       clearInterval(loadFont);
    //     }
    //   } else {
    //     // Fallback if API does not exist
    //     playStartAnimation();
    //     clearInterval(loadFont);
    //   }
    // }, 1000);
    // fn to set cookies
    // fn that enables GA
    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }
    // fn to get cookies
    function getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }

    // check cookies status
    const cookieBanner = document.querySelector('.cookie-banner');
    const cookiesStatus = getCookie('g0');

    // define gtag
    function gtag() {
      window.dataLayer.push(arguments);
    }

    const reportGA = (entries, observer) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        if (entry.target.id === 'howwedo') {
          gtag('event', 'how_we_do', {
            viewed: true,
          });
        } else if (entry.target.id === 'menu-link-2') {
          gtag('event', 'our_works', {
            viewed: true,
          });
        }
        observer.unobserve(entry.target);
      }
    };

    const addObserver = () => {
      const observerGA = new IntersectionObserver(reportGA, {
        root: null,
        threshold: 0,
      });
      // Homepage
      const howwedo = document.getElementById('howwedo');
      const projects = document.getElementById('menu-link-2');
      // Observer
      if (howwedo) {
        observerGA.observe(howwedo);
      }
      if (projects) {
        observerGA.observe(projects);
      }

      // Event listener
      const startProject = document.getElementById('new_project');
      if (startProject) {
        startProject.addEventListener(
          'click',
          () => {
            gtag('event', 'new_project', {
              clicked: true,
            });
          },
          { once: true }
        );
      }
      // Event listener
      const startProjectLink = document.getElementById('new-project-top');
      if (startProjectLink) {
        startProjectLink.addEventListener(
          'click',
          (e) => {
            gtag('event', 'new_project_top', {
              clicked: true,
            });
          },
          { once: true }
        );
      }

      // Start New Project page
      // Event listener
      const completeOrder = document.getElementById('complete_order');
      if (completeOrder) {
        completeOrder.addEventListener(
          'click',
          () => {
            gtag('event', 'complete_order', {
              clicked: true,
            });
          },
          { once: true }
        );
      }
    };
    const enableGA = () => {
      // set cookies
      setCookie('g0', 'true', 7);

      addObserver();

      // push data to gtag
      function consentGranted() {
        gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          functionality_storage: 'granted',
          personalization_storage: 'granted',
          security_storage: 'granted',
        });
      }
      gtag('js', new Date());

      gtag('config', 'G-ENLE2GX3LF');
      consentGranted();
    };

    // if were previously enabled, update gtag
    if (cookiesStatus) {
      cookieBanner.style.display = 'none';
      enableGA();
    }

    // else, display cookie banner
    const cookieOptions = document.querySelector('.options-wrapper');
    cookieOptions.addEventListener('click', (e) => {
      if (e.target.classList.contains('allow')) {
        cookieBanner.style.display = 'none';
        enableGA();
      }
      if (e.target.classList.contains('necessary')) {
        cookieBanner.style.display = 'none';
        enableGA();
      }
      if (e.target.classList.contains('leave')) {
        window.location.href = 'https://google.com/';
        // no cookies are saved, no need to clear
      }
    });
    const pathname = window.location.pathname;

    const tempUid = localStorage.getItem('y');
    if (loggedUserType === 1385155860) {
    } else {
      // const auth = getAuth();
      if (tempUid) {
        const tempObj = { lastNotifiedUid: tempUid };
        setLoggedUser(tempObj);
      }
      const userUid = loggedUser?.lastNotifiedUid;
      if (
        auth.lastNotifiedUid === userUid &&
        auth.lastNotifiedUid !== undefined
      ) {
        localStorage.setItem('y', auth.lastNotifiedUid);
      } else {
        // Re-enter fields
        const reEnt = () => {
          const n = window.sessionStorage.getItem('n');
          if (n) {
            setNewName(n);
            document.getElementById('contact-name').value = n;
          }

          const e = window.sessionStorage.getItem('e');
          if (e) {
            setNewEmail(e);
            document.getElementById('contact-email').value = e;
          }

          const a = window.sessionStorage.getItem('a');
          if (a) {
            setNewAddress(a);
            document.getElementById('contact-address').value = a;
          }

          const d = window.sessionStorage.getItem('d');
          if (d) {
            setNewDesc(d);
            // document.getElementById('contact-address').value = a;
          }
        };
        reEnt();
        signInAnonymously(auth)
          .then(() => {
            localStorage.setItem('y', auth.lastNotifiedUid);
            onAuthStateChanged(auth, () => {
              setLoggedUser(auth);
            });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
            // ...
          });
      }
    }
  }, []);

  useEffect(() => {
    // Text Intersection
    //Txt main selector
    const txtAnim = document.querySelectorAll('.text-cont');

    const obsCallback = function (elAll, observer) {
      const [el] = elAll;
      if (!el.isIntersecting) {
        //Check Class name

        const unTransform = () => {
          // Else: Get Children (Node Obj)
          const children = el.target.children;
          // Convert Node Object into an Array
          const listArray = [...children];

          listArray.forEach((item) => {
            const str = item.className
              .slice(-5)
              .replaceAll('-', '')
              .replace('m', '');
            //Configure the basic position
            if (str === 'down') item.style.transform = 'translateY(-200%)';
            else if (str === 'up') item.style.transform = 'translateY(200%)';
            else if (str === 'left') item.style.transform = 'translateX(-100%)';
            else if (str === 'right') item.style.transform = 'translateX(100%)';
          });
        };
        throttle(unTransform(), 250);
      }

      if (el.isIntersecting) {
        const transformIntr = () => {
          // Else: Get Children (Node Obj)
          const children = el.target.children;
          // Convert Node Object into an Array
          const listArray = [...children];

          listArray.forEach((item) => {
            //We check the className
            const str = item.className
              .slice(-5)
              .replaceAll('-', '')
              .replace('m', '');
            //Change Y or X
            if (str === 'down' || str === 'up')
              item.style.transform = 'translateY(0%)';
            else item.style.transform = 'translateX(0%)';
          });
        };
        throttle(transformIntr(), 250);
      }
      // observerTxt.unobserve(el.target);
    };

    const updateTranslate = function (arr) {
      arr.forEach((item) => {
        //Check Class name
        const str = item.className
          .slice(-5)
          .replaceAll('-', '')
          .replace('m', '');
        //Configure the basic position
        if (str === 'down') item.style.transform = 'translateY(-200%)';
        else if (str === 'up') item.style.transform = 'translateY(200%)';
        else if (str === 'left') item.style.transform = 'translateX(-100%)';
        else if (str === 'right') item.style.transform = 'translateX(100%)';
      });
    };

    function ObserveEl(elAll) {
      elAll.forEach((el) => {
        observerTxt.observe(el);

        // Get Children
        const children = el.children;
        // Convert Node Object into an Array & add translate
        updateTranslate([...children]);
      });
    }

    const observerTxt = new IntersectionObserver(obsCallback, {
      root: null,
      rootMargin: '50px',
      threshold: 1,
    });
    // ObserveEl(txtAnim);

    let idCounter = 0;
    const obsImgs = document.querySelectorAll('.anim');
    obsImgs.forEach((img) => {
      img.setAttribute('id', idCounter++);
      // img.setAttribute('anim', 'fade');
      if (img.classList.contains('text-anim')) {
        //Check Class name
        const str = img.className
          .slice(-5)
          .replaceAll('-', '')
          .replace('m', '');

        //Configure the basic position
        if (str === 'down') img.setAttribute('anim', 'down');
        else if (str === 'up') img.setAttribute('anim', 'up');
        else if (str === 'left') img.setAttribute('anim', 'left');
        else if (str === 'right') img.setAttribute('anim', 'right');
        contentObserver.observe(img);
      } else {
        img.setAttribute('anim', 'fade');
        img.setAttribute('id', idCounter);
      }
      contentObserver.observe(img);
    });

    // handleReCaptchaVerify();
    // is instantly displayed on site
    const testData = getAuth();
    const testDataUID = testData.lastNotifiedUid;
    if (testDataUID) {
      if (loggedUserType === 1385155860) {
        if (loggedUser.accessToken) {
          // for storage
          listAll(imageListRef).then((response) => {
            response.items.forEach((item) => {
              getDownloadURL(item).then((url) => {
                setImageUrls((prev) => [...prev, url]);
              });
            });
          });
          // onAuthStateChanged(auth, async (currentUser) => {
          //   console.log('logged as');
          //   console.log(currentUser);
          //   loginUiUpdate(currentUser);
          // });
        }
      }
    }

    // add imgs to array

    // Drag & Drop
    const imageUploadBox = document.querySelector('.spp1__imgupl');
    const refUploadBox = document.querySelector('.spp1__refupl');

    const throttle = (func, limit) => {
      let inThrottle;
      return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => (inThrottle = false), limit);
        }
      };
    };
    // need throtling
    let dragTarget = undefined;
    // Gallery Images Upload
    imageUploadBox?.addEventListener(
      'dragover',
      throttle(function (event) {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();
        if (event.target.closest('.spp1-form-img__box') === dragTarget) return;
        if (
          event.target.classList.contains('spp1-cujs') ||
          event.target.classList.contains('spp1-form-img__box--inside')
        ) {
          if (dragTarget) {
            dragTarget.classList.remove('spp1-form-dashed');
          }
          dragTarget = event.target.closest('.spp1-form-img__box');

          dragTarget.classList.add('spp1-form-dashed');
        } else {
          if (dragTarget) {
            dragTarget.classList.remove('spp1-form-dashed');
            dragTarget = undefined;
          }
        }
        event.dataTransfer.dropEffect = 'copy';
      }),
      1
    );
    // Referrences Images Upload
    refUploadBox?.addEventListener(
      'dragover',
      throttle(function (event) {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();
        if (event.target.closest('.spp1-form-img__box') === dragTarget) return;
        if (
          event.target.classList.contains('spp1-cujs') ||
          event.target.classList.contains('spp1-form-img__box--inside')
        ) {
          if (dragTarget) {
            dragTarget.classList.remove('spp1-form-dashed');
          }
          dragTarget = event.target.closest('.spp1-form-img__box');

          dragTarget.classList.add('spp1-form-dashed');
        } else {
          if (dragTarget) {
            dragTarget.classList.remove('spp1-form-dashed');
            dragTarget = undefined;
          }
        }
        event.dataTransfer.dropEffect = 'copy';
      }),
      1
    );
    // The upload mechanic itself (images)
    imageUploadBox?.addEventListener('drop', (event) => {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();

      const imgUpld = function (_realTarget) {
        const boxNum = event.target.parentElement.getAttribute('boxnumber');

        const fileList = event.dataTransfer.files;

        doImageDisplay(_realTarget, boxNum, true, fileList[0]);
      };
      if (event.target.classList.contains('spp1-cujs')) {
        if (event.target.tagName === 'P') {
          const realTarget = event.target.parentElement.lastElementChild;
          imgUpld(realTarget);
        }
        if (event.target.tagName === 'IMG') {
          const realTarget =
            event.target.parentElement.parentElement.lastElementChild;
          imgUpld(realTarget);
        }
      }
      if (event.target.classList.contains('spp1-form-img__box--inside')) {
        const realTarget = event.target.lastElementChild;
        imgUpld(realTarget);
      }
      if (dragTarget) {
        dragTarget.classList.remove('spp1-form-dashed');
      }
      // document.querySelector('#file_name').textContent = fileList[0].name;
      // readImage(fileList[0]);
    });
    // The upload mechanic itself (refs)
    refUploadBox?.addEventListener('drop', (event) => {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();

      const imgUpld = function (_realTarget) {
        const boxNum = event.target.parentElement.getAttribute('boxnumber');

        const fileList = event.dataTransfer.files;

        doImageDisplay(_realTarget, boxNum, true, fileList[0]);
      };
      if (event.target.classList.contains('spp1-cujs')) {
        if (event.target.tagName === 'P') {
          const realTarget = event.target.parentElement.lastElementChild;
          imgUpld(realTarget);
        }
        if (event.target.tagName === 'IMG') {
          const realTarget =
            event.target.parentElement.parentElement.lastElementChild;
          imgUpld(realTarget);
        }
      }
      if (event.target.classList.contains('spp1-form-img__box--inside')) {
        const realTarget = event.target.lastElementChild;
        imgUpld(realTarget);
      }
      if (dragTarget) {
        dragTarget.classList.remove('spp1-form-dashed');
      }
    });
  }, []);

  // New boxes Intersecting

  useEffect(() => {
    const obsImgs = document.querySelectorAll('.anim');
    let idCounter = 0;
    obsImgs.forEach((item) => {
      if (!item.id) {
        idCounter++;

        if (item.classList.contains('text-anim')) {
          //Check Class name
          const str = item.className
            .slice(-5)
            .replaceAll('-', '')
            .replace('m', '');

          //Configure the basic position
          if (str === 'down') item.setAttribute('anim', 'down');
          else if (str === 'up') item.setAttribute('anim', 'up');
          else if (str === 'left') item.setAttribute('anim', 'left');
          else if (str === 'right') item.setAttribute('anim', 'right');
          contentObserver.observe(item);
        } else {
          item.setAttribute('anim', 'fade');
          item.setAttribute('id', idCounter);
        }

        contentObserver.observe(item);
      }
    });
    // kekw
  }, [uploadBoxes]);
  return (
    <Router>
      <div className='App'>
        <Cookie />
        <Routes>
          <Route path='' element={<Home />} />
          <Route path='/privacypolicy' element={<Privacypolicy />} />
          <Route
            path='/admin'
            element={
              <>
                <div className='app__header'>
                  <div className='app__header--left'>
                    <div
                      className='app__refreshbtn'
                      onClick={() => {
                        adminRefresh();
                      }}
                    ></div>
                  </div>
                  <div className='app__header--right'>
                    <input
                      className='app__log-input'
                      placeholder='Email'
                      type='email'
                      onChange={(e) => {
                        setLoginEmail(e.target.value);
                      }}
                    ></input>
                    <input
                      className='app__log-input'
                      placeholder='Password'
                      type='password'
                      onChange={(e) => {
                        setLoginPassword(e.target.value);
                      }}
                    ></input>
                    <button
                      className='app__log-input btnMain'
                      onClick={() => {
                        adminLogin();
                      }}
                    >
                      Login
                    </button>
                    {/* {loggedUser?.email} */}
                    <button
                      className='app__logout-input btnMain'
                      onClick={() => {
                        adminLogout();
                      }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
                <div className='adm__app'>
                  <div
                    className='box-selection-wrapper'
                    onClick={(e) => {
                      if (
                        e.target.classList.contains('box-selection-wrapper') ||
                        e.target.classList.contains('box-selection-container')
                      ) {
                        closePricingFormAdmin();
                      }
                    }}
                  >
                    <div className='box-selection-container'>
                      <div className='box-selection-container__window container-large__Mt'>
                        <div className='box-selection_header'>
                          <div className='right-side__Mt'>
                            <div
                              className='right-side__close'
                              onClick={() => {
                                closePricingFormAdmin();
                              }}
                            >
                              <svg
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M17 14.2853L11.7166 9.00096L16.9981 3.71756L14.2834 1L8.99904 6.2834L3.71564 1L1 3.71756L6.28148 9.00096L1 14.2844L3.71756 17L8.99904 11.7166L14.2805 17L17 14.2853Z'
                                  fill='white'
                                  stroke='white'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <form action='#'>
                          <div className='form__Mt drag-off'>
                            {/* <div className="form__Mt--whole">Content</div> */}
                            <div className='form__Mt--roomblock'>
                              <div className='Mt__content Mt__heading Mt__heading--top'>
                                Room Options
                              </div>
                              <div className='Mt__content Mt__roomtype Mt__roomtype__grid'>
                                {roomOptions.map((option, i) => {
                                  return (
                                    <div className='Mt__subcontainer' key={i}>
                                      <div style={{ width: '100%' }}>
                                        <label className='Mt__label'>
                                          <span className='Mt__checkbox-wrapper'>
                                            <input
                                              type='checkbox'
                                              className='Mt__checkbox Mt__checkboxroom'
                                              onClick={(e) => {
                                                e.currentTarget.classList.toggle(
                                                  'active'
                                                );
                                                updateRoomForm(e);
                                              }}
                                            ></input>
                                            <span className='Mt__checkbox-color--white'>
                                              <svg
                                                className='Mt__check-icon'
                                                width='10'
                                                height='8'
                                                viewBox='0 0 8 6'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                              >
                                                <path
                                                  d='M7 1L3 5L1 3'
                                                  stroke='#fff'
                                                  strokeWidth='2'
                                                  strokeLinecap='round'
                                                  strokeLinejoin='round'
                                                ></path>
                                              </svg>
                                            </span>
                                          </span>

                                          <span className='Mt__title'>
                                            {option}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className='form__Mt--pricingblock'>
                              <div className='form__Mt--mid'>
                                <div className='Mt__content Mt__heading Mt__heading--top'>
                                  What needs to be done
                                </div>
                                <div className='Mt__content Mt__roomtype Mt__roomtype__grid--2'>
                                  {formOptions.map((option, i) => {
                                    if (i >= 2) {
                                      checkboxClass = 'Mt__label deactivated';
                                    }
                                    return (
                                      <>
                                        {i === 2 ? (
                                          <div className='grid__Mt--hr'></div>
                                        ) : null}
                                        {i === 4 ? (
                                          <div className='grid__Mt--hr'></div>
                                        ) : null}
                                        <div
                                          className='Mt__subcontainer'
                                          key={i}
                                        >
                                          <div style={{ width: '100%' }}>
                                            <label
                                              className={`${checkboxClass}`}
                                            >
                                              <span className='Mt__checkbox-wrapper'>
                                                <input
                                                  type='checkbox'
                                                  className='Mt__checkbox Mt__checkboxprice'
                                                  onClick={(e) => {
                                                    e.currentTarget.classList.toggle(
                                                      'active'
                                                    );
                                                    updatePricingForm(e);
                                                  }}
                                                ></input>
                                                <span className='Mt__checkbox-color--white'>
                                                  <svg
                                                    className='Mt__check-icon'
                                                    width='10'
                                                    height='8'
                                                    viewBox='0 0 8 6'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                  >
                                                    <path
                                                      d='M7 1L3 5L1 3'
                                                      stroke='#fff'
                                                      strokeWidth='2'
                                                      strokeLinecap='round'
                                                      strokeLinejoin='round'
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </span>

                                              <span className='Mt__title'>
                                                {option[(i, 0)]}
                                              </span>
                                              <span className='Mt__title--price'>
                                                {/* ${option[(i, 1)]} */}
                                                {newPrice[i] === 0 ||
                                                newPrice[i] === 999
                                                  ? ''
                                                  : `$${newPrice[i]}`}
                                                {newPrice[i] === 999 && (
                                                  <>
                                                    <p>Free</p>
                                                  </>
                                                )}
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>

                                <div className='form__Mt--right'>
                                  <div className='Mt__content'>
                                    <button
                                      className='btnMain'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        closePricingForm();
                                      }}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                  <div className='Mt__content Mt__pricing'>
                                    <span className='Mt__heading'>Price: </span>
                                    <div className='Mt__totalcost'>$0</div>
                                  </div>
                                </div>

                                {/* <div className="Mt__content Mt__pricing">
                    <span className="Mt__heading">Total: </span>
                    <div className="Mt__totalcost--all">${formSumTotal}</div>
                  </div> */}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <AdminCard
                    _actualUser={users}
                    _deleteUser={deleteUser}
                    _auth={loggedUser}
                    _openPricingForm={openPricingForm}
                  />
                  <Lightbox page={'admin'} />
                </div>
              </>
            }
          />

          <Route
            path='/newproject'
            element={
              <>
                <a href='/'>
                  <img
                    src='./img/PreLab Logo.png'
                    alt='prelab logo'
                    id='prelab-logo'
                  ></img>
                </a>
                <div className='checkout-section' id='chckLoader'>
                  <div className='complete-order-loader'>
                    <div
                      class='checkout-cross order-loader-cross'
                      onClick={() => {
                        closeFin();
                      }}
                    >
                      <div class='right-side__close'>
                        <svg
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17 14.2853L11.7166 9.00096L16.9981 3.71756L14.2834 1L8.99904 6.2834L3.71564 1L1 3.71756L6.28148 9.00096L1 14.2844L3.71756 17L8.99904 11.7166L14.2805 17L17 14.2853Z'
                            fill='black'
                            stroke='black'
                            stroke-linejoin='round'
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className='order-loader-subtitle'>
                      <p>Uploading image</p>
                    </div>
                    <div className='order-loader-timer'></div>
                    <div className='order-loader-text'>
                      <div className='lds-ring'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <div className='order-loader-title'>
                      <h3>Processing your order</h3>
                    </div>
                  </div>
                </div>

                <div
                  className='box-selection-wrapper'
                  onClick={(e) => {
                    if (
                      e.target.classList.contains('box-selection-wrapper') ||
                      e.target.classList.contains('box-selection-container')
                    ) {
                      closePricingForm();
                    }
                  }}
                >
                  <div className='box-selection-container'>
                    <div className='box-selection-container__window container-large__Mt'>
                      <div className='box-selection_header'>
                        <div className='right-side__Mt'>
                          <div
                            className='right-side__close'
                            onClick={() => {
                              closePricingForm();
                            }}
                          >
                            <svg
                              width='18'
                              height='18'
                              viewBox='0 0 18 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M17 14.2853L11.7166 9.00096L16.9981 3.71756L14.2834 1L8.99904 6.2834L3.71564 1L1 3.71756L6.28148 9.00096L1 14.2844L3.71756 17L8.99904 11.7166L14.2805 17L17 14.2853Z'
                                fill='white'
                                stroke='white'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <form action='#'>
                        <div className='form__Mt drag-off'>
                          {/* <div className="form__Mt--whole">Content</div> */}
                          <div className='form__Mt--roomblock'>
                            <div className='Mt__content Mt__heading Mt__heading--top'>
                              Room Options
                            </div>
                            <div className='Mt__content Mt__roomtype Mt__roomtype__grid'>
                              {roomOptions.map((option, i) => {
                                return (
                                  <div className='Mt__subcontainer' key={i}>
                                    <div style={{ width: '100%' }}>
                                      <label className='Mt__label'>
                                        <span className='Mt__checkbox-wrapper'>
                                          <input
                                            type='checkbox'
                                            className='Mt__checkbox Mt__checkboxroom'
                                            onClick={(e) => {
                                              e.currentTarget.classList.toggle(
                                                'active'
                                              );
                                              updateRoomForm(e);
                                            }}
                                          ></input>
                                          <span className='Mt__checkbox-color--white'>
                                            <svg
                                              className='Mt__check-icon'
                                              width='10'
                                              height='8'
                                              viewBox='0 0 8 6'
                                              fill='none'
                                              xmlns='http://www.w3.org/2000/svg'
                                            >
                                              <path
                                                d='M7 1L3 5L1 3'
                                                stroke='#fff'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                              ></path>
                                            </svg>
                                          </span>
                                        </span>

                                        <span className='Mt__title'>
                                          {option}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className='form__Mt--pricingblock'>
                            <div className='form__Mt--mid'>
                              <div className='Mt__content Mt__heading Mt__heading--top'>
                                What needs to be done
                              </div>
                              <div className='Mt__content Mt__roomtype Mt__roomtype__grid--2'>
                                {formOptions.map((option, i) => {
                                  if (i >= 2) {
                                    checkboxClass = 'Mt__label deactivated';
                                  }
                                  return (
                                    <>
                                      {i === 2 ? (
                                        <div className='grid__Mt--hr'></div>
                                      ) : null}
                                      {i === 4 ? (
                                        <div className='grid__Mt--hr'></div>
                                      ) : null}
                                      <div className='Mt__subcontainer' key={i}>
                                        <div style={{ width: '100%' }}>
                                          <label
                                            className={`${checkboxClass}`}
                                            title={`${formOptionsTitles.get(
                                              formOptions[i][0]
                                            )}`}
                                            alt={`${formOptionsTitles.get(
                                              formOptions[i][0]
                                            )}`}
                                          >
                                            <span className='Mt__checkbox-wrapper'>
                                              <input
                                                type='checkbox'
                                                className='Mt__checkbox Mt__checkboxprice'
                                                onClick={(e) => {
                                                  e.currentTarget.classList.toggle(
                                                    'active'
                                                  );
                                                  updatePricingForm(e);
                                                }}
                                              ></input>
                                              <span className='Mt__checkbox-color--white'>
                                                <svg
                                                  className='Mt__check-icon'
                                                  width='10'
                                                  height='8'
                                                  viewBox='0 0 8 6'
                                                  fill='none'
                                                  xmlns='http://www.w3.org/2000/svg'
                                                >
                                                  <path
                                                    d='M7 1L3 5L1 3'
                                                    stroke='#fff'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                  ></path>
                                                </svg>
                                              </span>
                                            </span>

                                            <span className='Mt__title'>
                                              {option[(i, 0)]}
                                            </span>
                                            <span className='Mt__title--price'>
                                              {/* ${option[(i, 1)]} */}
                                              {newPrice[i] === 0 ||
                                              newPrice[i] === 999
                                                ? ''
                                                : `$${newPrice[i]}`}
                                              {newPrice[i] === 999 && (
                                                <>
                                                  <p>Free</p>
                                                </>
                                              )}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>

                              <div className='form__Mt--right'>
                                <div className='Mt__content'>
                                  <button
                                    className='btnMain'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      closePricingForm();
                                    }}
                                  >
                                    Save Changes
                                  </button>
                                </div>
                                <div className='Mt__content Mt__pricing'>
                                  <span className='Mt__heading'>Price: </span>
                                  <div className='Mt__totalcost'>$0</div>
                                </div>
                              </div>

                              {/* <div className="Mt__content Mt__pricing">
                    <span className="Mt__heading">Total: </span>
                    <div className="Mt__totalcost--all">${formSumTotal}</div>
                  </div> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='spp1'>
                  <div className='spp1-header'>
                    <h2 className='anim'>
                      Starting<br></br>new project
                    </h2>
                  </div>
                  <div className='spp1-form-txt__block' id='spp-link-txt'>
                    <div className='spp1-form-txt anim'>
                      <div className='contact-group anim'>
                        <input
                          onChange={(event) => {
                            handleNewName(event);
                          }}
                          type='text'
                          name='name'
                          id='contact-name'
                          required
                        ></input>
                        <span className='highlight'></span>
                        <label htmlFor='Name'>Name*</label>
                      </div>
                      <div className='contact-group'>
                        <input
                          type='text'
                          name='email'
                          id='contact-email'
                          required
                          onChange={(event) => {
                            handleEmailChange(event, setNewEmail);
                          }}
                        ></input>
                        <span className='highlight'></span>
                        <label htmlFor='email'>E-mail*</label>
                      </div>
                      <div className='contact-group'>
                        <input
                          type='text'
                          name='address'
                          id='contact-address'
                          required
                          onChange={(event) => {
                            handleAddressChange(event);
                          }}
                        ></input>
                        <span className='highlight'></span>
                        <label htmlFor='email'>Property Address</label>
                      </div>
                    </div>
                  </div>
                  <div className='spp1-form-img__block' id='spp-link-img'>
                    <div className='spp1-form-img__block--top'>
                      <h2 className='anim'>Your room images</h2>
                      <div className='spp1-form-img-counter anim drag-off'>
                        <svg
                          width='24'
                          height='24'
                          className='spp1-counter__icon'
                          onClick={() => {
                            handleImagesBoxes(0);
                            handleImagesBoxes(0);
                            handleImagesBoxes(0);
                          }}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 -3.13132e-07 8 -6.99382e-07C3.58182 -1.08563e-06 1.08563e-06 3.58182 6.99382e-07 8C3.13132e-07 12.4182 3.58182 16 8 16ZM10.9091 8.72727C11.102 8.72727 11.287 8.65065 11.4234 8.51426C11.5597 8.37787 11.6364 8.19288 11.6364 8C11.6364 7.80711 11.5597 7.62213 11.4234 7.48574C11.287 7.34935 11.102 7.27273 10.9091 7.27273L5.09091 7.27273C4.89803 7.27273 4.71304 7.34935 4.57665 7.48574C4.44026 7.62213 4.36364 7.80711 4.36364 8C4.36364 8.19288 4.44026 8.37787 4.57665 8.51426C4.71304 8.65065 4.89802 8.72727 5.09091 8.72727L10.9091 8.72727Z'
                            fill='var(--main-gray)'
                          />
                        </svg>

                        <div className='spp1-counter__body'>{`${uploadBoxes}`}</div>

                        <svg
                          className='spp1-counter__icon'
                          width='24'
                          height='24'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          onClick={() => {
                            handleImagesBoxes(1);
                            handleImagesBoxes(1);
                            handleImagesBoxes(1);
                          }}
                        >
                          <path
                            d='M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM11.4286 8.42857C11.4286 8.50714 11.3643 8.57143 11.2857 8.57143H8.57143V11.2857C8.57143 11.3643 8.50714 11.4286 8.42857 11.4286H7.57143C7.49286 11.4286 7.42857 11.3643 7.42857 11.2857V8.57143H4.71429C4.63571 8.57143 4.57143 8.50714 4.57143 8.42857V7.57143C4.57143 7.49286 4.63571 7.42857 4.71429 7.42857H7.42857V4.71429C7.42857 4.63571 7.49286 4.57143 7.57143 4.57143H8.42857C8.50714 4.57143 8.57143 4.63571 8.57143 4.71429V7.42857H11.2857C11.3643 7.42857 11.4286 7.49286 11.4286 7.57143V8.42857Z'
                            fill='var(--main-gray)'
                          />
                        </svg>
                      </div>
                    </div>

                    <div className='spp1-form-img__block--upload spp1__imgupl drag-off'>
                      {uploadBoxesCount.map((val, i) => {
                        return (
                          <div
                            className='spp1-form-img__box  anim'
                            boxnumber={i}
                            key={i}
                          >
                            <div
                              className='spp1-cross-icon'
                              onClick={(e) => {
                                const boxNum = e.target
                                  .closest('.spp1-form-img__box')
                                  .getAttribute('boxnumber');
                                doCloudDisplay(e.currentTarget, arrDOM, boxNum);
                              }}
                            >
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 112 112'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M5 5L56 56L5 107'
                                  stroke='white'
                                  strokeWidth='10'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M107 5L56 56L107 107'
                                  stroke='white'
                                  strokeWidth='10'
                                  strokeLinecap='round'
                                />
                              </svg>
                            </div>
                            <label className='spp1-form-img__box--inside'>
                              <div className='spp1-cloud-icon'>
                                <img
                                  className='spp1-cujs'
                                  src='/upload-to-cloud.png'
                                  alt=''
                                ></img>
                              </div>
                              <p className='spp1-cujs'>
                                Click to upload your image
                                <h6 className='spp1-cujs-subtitle'>
                                  JPG, GIF, PNG
                                </h6>
                                <h6 className='spp1-cujs-subtitle spp1-cujs-subtitle__error'>
                                  Maximum file size is 22MB
                                </h6>
                              </p>

                              <input
                                type='file'
                                onChange={(event) => {
                                  const boxNum = event.target
                                    .closest('.spp1-form-img__box')
                                    .getAttribute('boxnumber');

                                  doImageDisplay(
                                    event.target,
                                    boxNum,
                                    false,
                                    event.target.files[0]
                                  );
                                }}
                              ></input>
                            </label>
                            <div className='spp1-form-price'>
                              <p>{formSum[i] > 0 && `$${formSum[i]}`}</p>
                            </div>
                            <div
                              className='spp1-form-btn'
                              onClick={(e) => {
                                const boxNum = e.target
                                  .closest('.spp1-form-img__box')
                                  .getAttribute('boxnumber');
                                if (e.target.classList.contains('btnMain')) {
                                  openPricingForm(boxNum);
                                }
                              }}
                            >
                              <button className='btnMain deactivated'>
                                Choose Room Options
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className='spp1-form-totsum'>
                      <h4 className='anim'>Total Price: $0</h4>
                      <hr className='spp1-hr' />
                    </div>
                    {/* Ref Images */}
                    <h2
                      className='spp1-form-img__block--ref anim'
                      id='spp-link-ref'
                    >
                      Your references
                    </h2>
                    <div className='spp1-form-img__block--upload spp1__refupl'>
                      {refBoxesCount.map((val, i) => {
                        return (
                          <div
                            className='spp1-form-img__box spp1-form-ref__box anim'
                            boxnumber={i + uploadBoxesCount.length}
                            key={i}
                          >
                            <div
                              className='spp1-cross-icon'
                              onClick={(e) => {
                                const boxNum = e.target
                                  .closest('.spp1-form-img__box')
                                  .getAttribute('boxnumber');
                                doCloudDisplay(e.currentTarget, arrDOM, boxNum);
                              }}
                            >
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 112 112'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M5 5L56 56L5 107'
                                  stroke='white'
                                  strokeWidth='10'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M107 5L56 56L107 107'
                                  stroke='white'
                                  strokeWidth='10'
                                  strokeLinecap='round'
                                />
                              </svg>
                            </div>
                            <label className='spp1-form-img__box--inside'>
                              <div className='spp1-cloud-icon'>
                                <img
                                  className='spp1-cujs'
                                  src='/upload-to-cloud.png'
                                  alt=''
                                ></img>
                              </div>
                              <p className='spp1-cujs'>
                                Click to upload your image
                                <h6 className='spp1-cujs-subtitle'>
                                  JPG, GIF, PNG
                                </h6>
                                <h6 className='spp1-cujs-subtitle spp1-cujs-subtitle__error'>
                                  Maximum file size is 22MB
                                </h6>
                              </p>

                              <input
                                type='file'
                                onChange={(event) => {
                                  const boxNum = event.target
                                    .closest('.spp1-form-img__box')
                                    .getAttribute('boxnumber');

                                  doImageDisplay(
                                    event.target,
                                    boxNum,
                                    false,
                                    event.target.files[0]
                                  );
                                }}
                              ></input>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className='spp1-form-img__block--upload2'></div>
                    <div className='spp1-style-select'>
                      <div className='text-cont'>
                        <h2 className='anim text-anim text-anim--up'>Style</h2>
                      </div>
                      <details class='spp1-custom-select anim'>
                        <summary class='radios'>
                          <input
                            type='radio'
                            name='Empty'
                            id='default'
                            title='Empty'
                          ></input>
                          <input
                            type='radio'
                            name='item'
                            id='item1'
                            title='Contemporary'
                            onClick={(e) => {
                              handleStyleSelect(e);
                            }}
                          ></input>
                          <input
                            type='radio'
                            name='item'
                            id='item2'
                            title='Modern'
                            onClick={(e) => {
                              handleStyleSelect(e);
                            }}
                          ></input>
                          <input
                            type='radio'
                            name='item'
                            id='item3'
                            title='Transitional'
                            onClick={(e) => {
                              handleStyleSelect(e);
                            }}
                          ></input>
                          <input
                            type='radio'
                            name='item'
                            id='item4'
                            title='Traditional'
                            onClick={(e) => {
                              handleStyleSelect(e);
                            }}
                          ></input>
                          <input
                            type='radio'
                            name='item'
                            id='item5'
                            title='Mid-Century'
                            onClick={(e) => {
                              handleStyleSelect(e);
                            }}
                          ></input>
                          <input
                            type='radio'
                            name='item'
                            id='item6'
                            title='Scandinavian'
                            onClick={(e) => {
                              handleStyleSelect(e);
                            }}
                          ></input>
                          <input
                            type='radio'
                            name='item'
                            id='item7'
                            title='Other'
                            onClick={(e) => {
                              handleStyleSelect(e);
                            }}
                          ></input>
                        </summary>
                        <ul class='list'>
                          <li>
                            <label for='item1'>
                              Contemporary<span></span>
                            </label>
                          </li>
                          <li>
                            <label for='item2'>Modern</label>
                          </li>
                          <li>
                            <label for='item3'>Transitional</label>
                          </li>
                          <li>
                            <label for='item4'>Traditional</label>
                          </li>
                          <li>
                            <label for='item5'>Mid-Century</label>
                          </li>
                          <li>
                            <label for='item6'>Scandinavian</label>
                          </li>
                          <li>
                            <label for='item7'>Other</label>
                          </li>
                        </ul>
                      </details>
                      <div className='pinterest-link-container anim'>
                        <a
                          className='colorful-link'
                          href='https://www.pinterest.com/prelabdesign/'
                          target='_blank'
                        >
                          <p>Find your style </p>
                          <div className='fa-brands fa-pinterest'></div>
                        </a>
                      </div>
                    </div>
                    <div
                      className='spp1-form-img__block--desc'
                      id='spp-link-desc'
                    >
                      <h3 className='anim'>Describe your property</h3>
                      <h5
                        className='anim'
                        style={{ animationDuration: '1.5s' }}
                      >
                        <li>
                          <b>General:</b> list price, room height, etc...
                        </li>
                        <li>
                          <b>Your buyers:</b> 1st time buyers, young family, etc
                        </li>
                        <li>
                          <b>Other:</b> any in-room suggestions you might have
                        </li>
                      </h5>
                    </div>
                    <div className='spp1-textedit__container'>
                      <div className='contact-group anim'>
                        {/* <textarea
              onChange={(event) => {
                setNewDesc(event.target.value);
              }}
              rows="5"
              cols="20"
            ></textarea> */}
                        <RichTextEditor
                          value={newDesc}
                          onChange={setNewDesc}
                          controls={[
                            ['h1', 'h2', 'h3'],
                            ['bold', 'italic', 'underline', 'strike'],
                            ['clean'],
                            ['alignLeft', 'alignCenter', 'alignRight'],
                            ['unorderedList', 'orderedList'],
                            ['link'],
                          ]}
                        />

                        <label htmlFor='Description' id='label-description'>
                          Enter description here*
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Pick Style here */}
                  <ChromePicker
                    disableAlpha={true}
                    color={color}
                    onChange={(color) => {
                      setColor(color.hex);
                      handleColor();
                    }}
                  />
                  <div className='spp-color-section' id='spp-link-color'>
                    <div className='spp-color-section-bg'></div>
                    <div className='color-picker-bg'></div>

                    <h3 className='anim'>Pick Your Colors</h3>
                    <div className='spp-color-container'>
                      <div className='spp-color-likes spp-color-container__inside'>
                        <h4 className='anim'>Colors You Like</h4>
                        <div
                          className='spp-color-palette anim'
                          style={{ animationDuration: '1.5s' }}
                          onClick={(e) => {
                            if (e.target.classList.contains('spp-color-box')) {
                              colorTarget = e.target;

                              showPicker();
                            }
                          }}
                        >
                          {colorLike.map((color, i) => {
                            return (
                              <div
                                className='spp-color-box spp-color-box__like'
                                cbox={i}
                                key={i}
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                      <div className='spp-color-dislikes spp-color-container__inside'>
                        <h4 className='anim'>Color You Dislike</h4>
                        <div
                          className='spp-color-palette anim'
                          style={{ animationDuration: '1.5s' }}
                          onClick={(e) => {
                            if (e.target.classList.contains('spp-color-box')) {
                              colorTarget = e.target;

                              showPicker();
                            }
                          }}
                        >
                          {colordisLike.map((color, i) => {
                            return (
                              <div
                                className='spp-color-box spp-color-box__dislike'
                                cbox={i}
                                key={i}
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <button
                      className='btnMain btnBlue anim'
                      style={{ marginTop: '3rem' }}
                      onClick={() => {
                        // uploadImage();
                        // showValidation();
                        openCheckout();
                        // testStuff();
                      }}
                    >
                      {' '}
                      Submit
                    </button>
                    <div
                      className='socialMedia '
                      style={{ maxWidth: '200px', margin: '1rem auto' }}
                    >
                      <a
                        className='fa-brands fa-facebook'
                        href='https://www.facebook.com/PrelabDesign'
                        target='_blank'
                      ></a>

                      <a
                        className='fa-brands fa-instagram '
                        href='https://www.instagram.com/prelab.studio/'
                        target='_blank'
                      ></a>

                      <a
                        className='fa-brands fa-twitter-square '
                        href='https://twitter.com/PrelabDesign'
                        target='_blank'
                      ></a>

                      <a
                        className='fa-brands fa-telegram '
                        href='https://t.me/Prelab_design_bot'
                        target='_blank'
                      ></a>
                    </div>
                  </div>
                  <Lightbox page={'newproject'} />
                  {/* <input
          type="file"
          onChange={(event) => {
            setImageUpload(event.target.files[0]);

          }}

        ></input> */}
                  <div type='footer' className='section-wrapper footer-section'>
                    <a href='https://www.averyano.com/'>
                      <div className='adLogo'>
                        <a href='https://www.averyano.com/'>
                          <img src='./av-black.png' alt=''></img>
                        </a>
                      </div>
                    </a>
                    <div id='rightsText'>(c) 2022. All rights reserved.</div>
                    <a
                      href='mailto:support@prelab.design'
                      className='mail-to colorful-link'
                    >
                      support@prelab.design
                    </a>
                  </div>
                </div>
                <div
                  className='checkout-section'
                  id='chckSection'
                  onClick={(e) => {
                    if (
                      e.target.classList.contains('checkout-section__wrapper')
                    ) {
                      closeValidation();
                    }
                  }}
                >
                  <div className='checkout-section__wrapper'>
                    <div
                      className='checkout-section'
                      id='chckValidation'
                      onClick={(e) => {
                        if (e.target.classList.contains('checkout-section')) {
                          closeValidation();
                        }
                      }}
                    >
                      <div className='checkout-confirmation-pre'>
                        <div className='checkout-confirmation-pre__top'>
                          <h3
                            className='checkout-confirmation-pre__top--header'
                            style={{ fontSize: '100%' }}
                          >
                            Found 3 issues
                          </h3>
                        </div>
                        <div className='checkout-hr-hori-linear'></div>
                        <div className='checkout-confirmation-pre__mid'>
                          <ul className='checkout-caution'>
                            <li>
                              <img src='/checkmark.svg'></img>
                              <h5>Text is missing</h5>
                              <a href='#' alt='scroll to'>
                                <img src='/scrollto.svg'></img>
                              </a>
                            </li>
                            <li>
                              <img src='/caution-circle.svg'></img>
                              <h5>Email is missing</h5>
                              <a href='#'>scroll to</a>
                            </li>
                            <li>
                              <img src='/cross.svg'></img>
                              <h5>No images is missing</h5>
                              <a href='#'>scroll to</a>
                            </li>
                          </ul>
                        </div>
                        <div className='checkout-confirmation-pre__bot'>
                          <button
                            className='btnMain'
                            onClick={() => {
                              closeValidation();
                            }}
                          >
                            BACK
                          </button>
                          <button
                            className='btnMain deactivated'
                            onClick={() => {
                              completeOrder();
                            }}
                          >
                            CONTINUE
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='checkout-wrapper'>
                      <div className='checkout-block checkout-block__name'>
                        <div
                          class='checkout-cross right-side__Mt'
                          onClick={() => {
                            closeValidation();
                          }}
                        >
                          <div class='right-side__close'>
                            <svg
                              width='18'
                              height='18'
                              viewBox='0 0 18 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M17 14.2853L11.7166 9.00096L16.9981 3.71756L14.2834 1L8.99904 6.2834L3.71564 1L1 3.71756L6.28148 9.00096L1 14.2844L3.71756 17L8.99904 11.7166L14.2805 17L17 14.2853Z'
                                fill='black'
                                stroke='black'
                                stroke-linejoin='round'
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <h2>Almost there...</h2>
                      </div>
                      <div className='checkout-block checkout-block__leftstyle'>
                        <h4 className='checkout-block--left'>Name</h4>
                        <div className='checkout-hr-vert'></div>
                        <h4 className='checkout-block--right' id='chckName'>
                          Name
                        </h4>
                      </div>
                      <div className='checkout-block checkout-block__leftstyle'>
                        <h4 className='checkout-block--left'>Email</h4>
                        <div className='checkout-hr-vert'></div>
                        <h4 className='checkout-block--right' id='chckEmail'>
                          E-mail
                        </h4>
                      </div>
                      <div className='checkout-block checkout-block__leftstyle'>
                        <h4 className='checkout-block--left'>Address</h4>
                        <div className='checkout-hr-vert'></div>
                        <h4 className='checkout-block--right' id='chckAddress'>
                          987 Grand St, Brooklyn, NY 11211, United States
                        </h4>
                      </div>
                      <div className='checkout-block checkout-block__topstyle'>
                        <div className='checkout-block--top'>
                          <h4 className='checkout-block--top__text'>Images</h4>
                          <div className='checkout-block--top__textother'></div>
                        </div>
                        <div className='checkout-hr-hori'></div>
                        <div className='checkout-block--bottom'>
                          <div
                            className='checkout-block--imagewrapper__grid'
                            id='chckImgs'
                          ></div>
                        </div>
                      </div>
                      <div className='checkout-block checkout-block__topstyle'>
                        <div className='checkout-block--top'>
                          <h4 className='checkout-block--top__text'>
                            References
                          </h4>
                          <div className='checkout-block--top__textother'></div>
                        </div>
                        <div className='checkout-hr-hori'></div>
                        <div className='checkout-block--bottom'>
                          <div
                            className='checkout-block--imagewrapper__grid'
                            id='chckRefs'
                          >
                            <figure>
                              <img src='' className='checkout-img'></img>
                              <figcaption>
                                {/* <h5 className="checkout-img-title">Kitchen Room</h5> */}
                              </figcaption>
                            </figure>
                            <figure>
                              <img src='' className='checkout-img'></img>
                              <figcaption>
                                {/* <h5 className="checkout-img-title">Kitchen Room</h5> */}
                              </figcaption>
                            </figure>
                            <figure>
                              <img src='' className='checkout-img'></img>
                              <figcaption>
                                {/* <h5 className="checkout-img-title">Kitchen Room</h5> */}
                              </figcaption>
                            </figure>
                            <figure>
                              <img src='' className='checkout-img'></img>
                              <figcaption>
                                {/* <h5 className="checkout-img-title">Kitchen Room</h5> */}
                              </figcaption>
                            </figure>
                            <figure>
                              <img src='' className='checkout-img'></img>
                              <figcaption>
                                {/* <h5 className="checkout-img-title">Kitchen Room</h5> */}
                              </figcaption>
                            </figure>
                            <figure>
                              <img src='' className='checkout-img'></img>
                              <figcaption>
                                {/* <h5 className="checkout-img-title">Kitchen Room</h5> */}
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className='checkout-block checkout-block__leftstyle'>
                        <h4 className='checkout-block--left'>Style</h4>
                        <div className='checkout-hr-vert'></div>
                        <h4 className='checkout-block--right' id='chckStyle'>
                          {sppStyle}
                        </h4>
                      </div>
                      <div className='checkout-block checkout-block__topstyle'>
                        <div className='checkout-block--top'>
                          <h4 className='checkout-block--top__text'>
                            Description
                          </h4>
                          <div className='checkout-block--top__textother'></div>
                        </div>
                        <div className='checkout-hr-hori'></div>
                        <div className='checkout-block--bottom'>
                          <div className='checkout-block--desc'>
                            <div id='chckDesc'></div>
                          </div>
                        </div>
                      </div>
                      <div className='checkout-block checkout-block__topstyle'>
                        <div className='checkout-block--top'>
                          <h4 className='checkout-block--top__text'>Colors</h4>
                          <div className='checkout-block--top__textother'></div>
                        </div>
                        <div className='checkout-hr-hori'></div>
                        <div className='checkout-block--bottom'>
                          <div className='checkout-block--desc'>
                            <div id='chckColors'></div>
                          </div>
                        </div>
                      </div>
                      <div className='checkout-block checkout-block__topstyle'>
                        <div className='checkout-terms'>
                          <div className='checkout-sub__block'>
                            <label className='Mt__label-end'>
                              <span className='Mt__checkbox-wrapper'>
                                <input
                                  type='checkbox'
                                  className='Mt__checkbox'
                                  onClick={(e) => {
                                    e.currentTarget.classList.toggle('active');
                                    setNewSub(!newSub);
                                  }}
                                ></input>
                                <span className='Mt__checkbox-color--white'>
                                  <svg
                                    className='Mt__check-icon'
                                    width='10'
                                    height='8'
                                    viewBox='0 0 8 6'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M7 1L3 5L1 3'
                                      stroke='#fff'
                                      strokeWidth='2'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    ></path>
                                  </svg>
                                </span>
                              </span>

                              <span className='Mt__title'>
                                Subscribe to News
                              </span>
                            </label>
                          </div>
                          <div className='checkout-terms__block'>
                            <label className='Mt__label-end'>
                              <span className='Mt__checkbox-wrapper'>
                                <input
                                  type='checkbox'
                                  className='Mt__checkbox active'
                                  onClick={(e) => {
                                    e.currentTarget.classList.toggle('active');
                                    setNewTerms(!newTerms);
                                    document
                                      .getElementById('chckComplete')
                                      .firstElementChild.classList.toggle(
                                        'deactivated'
                                      );
                                  }}
                                ></input>
                                <span className='Mt__checkbox-color--white'>
                                  <svg
                                    className='Mt__check-icon'
                                    width='10'
                                    height='8'
                                    viewBox='0 0 8 6'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M7 1L3 5L1 3'
                                      stroke='#fff'
                                      strokeWidth='2'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    ></path>
                                  </svg>
                                </span>
                              </span>

                              <span
                                className='Mt__title'
                                style={{ textDecoration: 'underline' }}
                              >
                                <a target='_blank' href='/privacypolicy'>
                                  I accept the Terms and Privacy Policy
                                </a>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='checkout-block checkout-block__leftstyle'>
                        <h4 className='checkout-block--left'>Promo</h4>

                        <input
                          type='text'
                          name='promocode'
                          className='checkout-promo'
                          placeholder='Your promocode...'
                          onChange={(e) => {
                            handlePromoValue(e);
                          }}
                        ></input>
                        <button
                          className='checkout-promo__button'
                          onClick={(e) => {
                            handlePromoApply(e);
                          }}
                        >
                          Apply code
                        </button>
                      </div>
                      <div className='checkout-block checkout-block__leftstyle'>
                        <h4 className='checkout-block--left'>Total</h4>
                        <div className='checkout-hr-vert'></div>
                        <h4 className='checkout-block--right' id='chckTotal'>
                          $0
                        </h4>
                        <div className='checkout-hr-vert'></div>

                        <h4 className='checkout-block--right' id='chckComplete'>
                          <button
                            className='btnMain btnBlue'
                            id='complete_order'
                            onClick={() => {
                              validateCheck();
                              // completeOrder();
                            }}
                          >
                            Complete Order
                          </button>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          ></Route>
          <Route path='/404' element={<NotFound />}></Route>
          <Route path='/*' element={<NotFound />}></Route>
        </Routes>
      </div>
      <Revealer />
    </Router>
  );
}

export default App;
