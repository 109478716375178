import React from 'react';
import thevideo from '../vid/prelab preview video.mp4';
import Lightbox from './Lightbox';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import lozad from 'lozad';
function Home() {
  // Close contact

  const openContact = function () {
    const contactModal = document.querySelector('.modal-contact');
    if (contactModal) contactModal.style.display = 'block';

    const addContact = setTimeout(() => {
      if (contactModal) contactModal.style.opacity = 1;
      clearTimeout(addContact);
    }, 10);
  };
  const closeContact = function () {
    const contactModal = document.querySelector('.modal-contact');
    if (contactModal) contactModal.style.opacity = 0;
    const removeContact = setTimeout(() => {
      if (contactModal) contactModal.style.display = 'none';
      clearTimeout(removeContact);
    }, 301);
  };
  // for vid
  const videoRef = useRef(null);
  useEffect(() => {
    const { current: videoElement } = videoRef;
    videoElement.setAttribute('muted', '');
    // Homepage box
    // Parallax Cover
    // (function () {
    //   const coverBar = document.querySelector('.cover-video-anim');

    //   const parListener = window.addEventListener('scroll', () => {
    //     const pos = window.pageYOffset * 0.05;
    //     if (pos < 100) {
    //       coverBar.style.transform = `translate3d(0px, ${pos}px, 0px)`;
    //     }
    //   });
    // })();

    const otherScripts = () => {
      const scrollSection = document.querySelector('.progress-area');
      const scrollPercentBAR = document.querySelector('.progress-bar');
      const mediaQuery999 = 'screen only and (max-width:999px)';
      let scrollTop = 0;
      let scrollBottom = 0;
      let scrollPercent = 0;
      const scrollHeight = function () {
        scrollTop =
          document.documentElement['scrollTop'] || document.body['scrollTop'];
        scrollBottom =
          (document.documentElement['scrollHeight'] ||
            document.body['scrollHeight']) -
          document.documentElement.clientHeight;
        scrollPercent = (scrollTop / scrollBottom) * 100;
        if (scrollPercent >= 97) scrollPercent = 100;

        scrollPercentBAR.style.height = Math.trunc(scrollPercent) + '%';

        // debuggerTxt.textContent = scrollPercent;
      };
      /* Adds overlay to menu */

      const coverSect = document.querySelector('.cover-video-anim');
      const whiteOverlay = document.querySelector('.whiteOverlay');
      whiteOverlay.style.backgroundColor = `rgba(0, 0, 0, 0)`;
      whiteOverlay.style.backdropFilter = `blur(0px)`;
      let menuOverlay = false;

      function throttle(fn, wait) {
        let time = Date.now();
        return function () {
          if (time + wait - Date.now() < 0) {
            fn();
            time = Date.now();
          }
        };
      }
      function callback() {
        if (coverSect.getBoundingClientRect().y < -64) {
          if (!menuOverlay) {
            menuOverlay = true;
            whiteOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.10)';
            whiteOverlay.style.backdropFilter = `blur(${4}px)`;
          }
        } else {
          if (menuOverlay) {
            menuOverlay = false;
            whiteOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0)';
            whiteOverlay.style.backdropFilter = `blur(${0}px)`;
          }
        }
      }
      window.addEventListener('scroll', throttle(scrollHeight, 50));
      // ^
      // ^
      // ^
      // ^
      // ^
      // ^
      // ^

      // ^
      // ^
      // ^
      // ^
      // ^
      // ^
      // ^
      const fadeAnim = document.querySelectorAll('.fadeAnim');

      // const navHeight = fadeAnim.getBoundingClientRect().height; //getting the height of an element

      const navHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight; //Size of the browser window

      const obsCallbackFade = function (entries, observer) {
        const [entry] = entries;
        // if (!entry.isIntersecting) return;
        if (!entry.isIntersecting)
          return entry.target.classList.remove('fadeInUp');
        // entry.target.classList.remove('animatedFadeInUp');
        if (entry.isIntersecting) {
          entry.target.classList.add('fadeInUp');
        }
        // observer.unobserve(entry.target);

        // else labelTimer.classList.add('hidden');
      };

      const obsOptions = {
        root: null, //entire viewport
        rootMargin: '200px',
        //[0, 0.2, 0.5] //trigger it a bit earlier (+ value is later)
        // rootMargin: `-${navHeight}px`,
      };

      const observer = new IntersectionObserver(obsCallbackFade, obsOptions);

      function addFadeInUp(section) {
        section.forEach((element) => {
          // element.classList.add('animatedFadeInUp');
          element.style.opacity = 0;
          observer.observe(element);
        });
      }

      addFadeInUp(fadeAnim);
      /* TXT Element Appearance by Ad */

      //Txt main selector
      const txtAnim = document.querySelectorAll('.text-cont');

      const obsCallback = function (elAll, observer) {
        const [el] = elAll;
        if (!el.isIntersecting) {
          //Check Class name

          const unTransform = () => {
            // Else: Get Children (Node Obj)
            const children = el.target.children;
            // Convert Node Object into an Array
            const listArray = [...children];

            listArray.forEach((item) => {
              const str = item.className
                .slice(-5)
                .replaceAll('-', '')
                .replace('m', '');
              //Configure the basic position
              if (str === 'down') item.style.transform = 'translateY(-200%)';
              else if (str === 'up') item.style.transform = 'translateY(200%)';
              else if (str === 'left')
                item.style.transform = 'translateX(-100%)';
              else if (str === 'right')
                item.style.transform = 'translateX(100%)';
            });
          };
          throttle(unTransform(), 250);
        }
        if (el.isIntersecting) {
          const transformIntr = () => {
            // Else: Get Children (Node Obj)
            const children = el.target.children;
            // Convert Node Object into an Array
            const listArray = [...children];

            listArray.forEach((item) => {
              //We check the className
              const str = item.className
                .slice(-5)
                .replaceAll('-', '')
                .replace('m', '');
              //Change Y or X
              if (str === 'down' || str === 'up')
                item.style.transform = 'translateY(0%)';
              else item.style.transform = 'translateX(0%)';
            });
          };
          throttle(transformIntr(), 250);
        }
        // observerTxt.unobserve(el.target);
      };

      const updateTranslate = function (arr) {
        arr.forEach((item) => {
          //Check Class name
          const str = item.className
            .slice(-5)
            .replaceAll('-', '')
            .replace('m', '');
          //Configure the basic position
          if (str === 'down') item.style.transform = 'translateY(-200%)';
          else if (str === 'up') item.style.transform = 'translateY(200%)';
          else if (str === 'left') item.style.transform = 'translateX(-100%)';
          else if (str === 'right') item.style.transform = 'translateX(100%)';
        });
      };

      function ObserveEl(elAll) {
        elAll.forEach((el) => {
          observerTxt.observe(el);

          // Get Children
          const children = el.children;
          // Convert Node Object into an Array & add translate
          updateTranslate([...children]);
        });
      }

      const observerTxt = new IntersectionObserver(obsCallback, {
        root: null,
        rootMargin: '50px',
        threshold: 1,
      });
      ObserveEl(txtAnim);

      const mediaQuery = window.matchMedia('(min-width: 1000px)');

      const addQuery = (e) => {
        if (e.matches) {
          virtualBox();
          window.addEventListener('scroll', throttle(callback, 200));

          window.addEventListener('scroll', () => {
            if (coverSect.getBoundingClientRect().y >= -64) {
              if (menuOverlay) {
                callback();
                menuOverlay = false;
              }
            }
          });
        } else {
          menuOverlay = true;
          whiteOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.10)';
          whiteOverlay.style.backdropFilter = `blur(${4}px)`;
          // #000 2px -1.7px
        }
      };
      addQuery(mediaQuery);
    };

    const virtualBox = () => {
      // Define variables
      let mousePosX = 0;
      let mousePosY = 0;
      let screenX = window.innerWidth;
      let screenY = window.innerHeight;
      let deviceUsed = 'desktop'; // or 'mobile'
      let mousePosX100 = mousePosX / 100;
      let mousePosY100 = mousePosX / 100;
      // 30px and 45px are used to keep the shadow in place & control the distance

      const coverSection = document.querySelector('.coverSection');

      const texty = document.querySelector('.virtual-real-box__text');
      const boxy = document.querySelector('.virtual-real-box');
      boxy.style.boxShadow = '-5px 12px rgb(0 0 0)';

      coverSection.onmousemove = (evt) => {
        if (deviceUsed === 'desktop') {
          // Get the cursor position
          mousePosX = evt.clientX - screenX + screenX / 2;
          mousePosY = evt.clientY - screenY + screenY / 2;

          // Move the shadow and box (x2 slower)
          boxy.style.boxShadow = `${mousePosX / 30}px ${
            mousePosY / 45
          }px rgb(0 0 0)`;
          boxy.style.marginLeft = `${mousePosX / 60}px`;
          boxy.style.marginTop = `${mousePosY / 90}px`;
          // texty.style.textShadow = `rgb(0,0,0) ${-mousePosX100}px ${mousePosY100}px`;

          mousePosX100 = mousePosX / 50;
          mousePosX100 = mousePosY / 50;
          if (mousePosX100 > 3) {
            mousePosX100 = 3;
          }
          if (mousePosX100 < -3) {
            mousePosX100 = -3;
          }
          if (mousePosY100 > 3) {
            mousePosY100 = 3;
          }
          if (mousePosY100 < -3) {
            mousePosY100 = -3;
          }

          // mousePosY100 = mousePosX * 0.1;
          texty.style.textShadow = `rgb(0,0,0) ${-mousePosX / 200}px ${
            -mousePosY / 200
          }px`;
          // if (mousePosX > 0 && mousePosY < 0) {
          //   texty.style.textShadow = `rgb(0,0,0) ${-mousePosX / 50}px ${
          //     -mousePosY / 50
          //   }px`;
          // } else if (mousePosX > 0 && mousePosY > 0) {
          //   texty.style.textShadow = `rgb(0,0,0) ${-mousePosX / 50}px ${
          //     -mousePosY / 50
          //   }px`;
          // } else if (mousePosX < 0 && mousePosY < 0) {
          //   texty.style.textShadow = `rgb(0,0,0) ${-mousePosX / 50}px ${
          //     -mousePosY / 50
          //   }px`;
          // } else if (mousePosX < 0 && mousePosY > 0) {
          //   texty.style.textShadow = `rgb(0,0,0) ${-mousePosX / 50}px ${
          //     -mousePosY / 50
          //   }px`;
          // }
        }
      };

      // Update the screenSize
      let doit; // for timer

      window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(function () {
          rescaleUI();
        }, 100);
      };

      const rescaleUI = function () {
        // Update X Y

        handleDeviceChange(mediaQuery);
        screenX = window.innerWidth;
        screenY = window.innerHeight;
        clearTimeout(doit);
      };

      const mediaQuery = window.matchMedia('(min-width: 1000px)');

      function handleDeviceChange(e) {
        if (e.matches) {
          deviceUsed = 'desktop';
        }
        // Move the shadow and box (x2 slower)
        else {
          deviceUsed = 'mobile';
          boxy.style.boxShadow = `${-7}px ${5}px rgb(0 0 0)`;
          texty.style.textShadow = `rgb(0,0,0) ${2}px ${-1.7}px`;
          boxy.style.marginLeft = `${0}px`;
          boxy.style.marginTop = `${0}px`;
        }
      }
      handleDeviceChange(mediaQuery);
    };

    // lozad
    const observerLd = lozad('.lazy-load', {
      rootMargin: '1000px 0px', // syntax similar to that of CSS Margin
      threshold: 0, // ratio of element convergence
      enableAutoReload: true, // it will reload the new image when validating attributes changes

      // load: function (el) {
      //   el.classList.add('animated');
      // },
      loaded: function (el) {
        //   // Custom implementation on a loaded element
        el.classList.add('lazy-loaded');
      },
    });
    observerLd.observe();
    otherScripts();
    // Virtual is real box
  }, []);

  return (
    <>
      <div id='top'></div>

      <div className='progress-bar'></div>
      <div className='modal-contact'>
        <div className='modal-contact-wrapper'>
          <h3>Email Us</h3>
          <span
            className='close-contact pointer'
            onClick={() => {
              closeContact();
            }}
          >
            &times;
          </span>
          <form id='contactForm' method='POST' data-netlify='true'>
            <p className='contact-group'>
              <input type='text' name='name' id='contact-name' required></input>
              <span className='highlight'></span>
              <label id='labelName' for='firstname'>
                Name
              </label>
            </p>
            <p className='contact-group'>
              <input
                type='text'
                name='email'
                id='contact-email'
                required
              ></input>
              <span className='highlight'></span>
              <label id='labelEmail' for='email'>
                E-mail
              </label>
            </p>
            <p className='contact-group'>
              <textarea
                name='message'
                rows='5'
                id='contact-textarea'
                required
              ></textarea>
              <span className='highlight'></span>
              <label id='labelMsg' for='msg'>
                Message
              </label>
            </p>
            <p className='contact-group'>
              <div data-netlify-recaptcha='true'></div>
            </p>
            <p>
              <a href='#top' className='send-btn'>
                Submit
              </a>
            </p>
          </form>
        </div>
      </div>
      <header className='header whiteOverlay'>
        <nav id='MainMenuId' className='main-menu' type='MainMenu'>
          <div data-elements-position='top' className='adMenu first'>
            <div id='MAINMENULOGO'>
              <a href='#top'>
                <img src='./img/PreLab Logo.png' alt=''></img>
              </a>
            </div>
          </div>
          <div data-elements-position='bottom' className='adMenu second'>
            <input type='checkbox' className='menu-btn' id='menu-btn'></input>
            <label className='menu-icon' for='menu-btn'>
              <span className='nav-icon'></span>
            </label>

            <div className='main-menu-links'>
              <div className='link-wrapper'>
                <a href='#menu-link-1'>About Us</a>
              </div>
              <div className='link-wrapper'>
                <a href='#menu-link-2'>Portfolio</a>
              </div>
              {/* <div className="link-wrapper">
                <a href="#menu-link-3">Prices</a>
              </div> */}
              <div className='link-wrapper'>
                <div className='startProjectContainer'>
                  <div className='startProjectTxt'>
                    <a href='/newproject' id='new-project-top'>
                      Start New Project
                    </a>
                  </div>
                  <svg
                    id='newprj-arrow'
                    width='7'
                    height='7'
                    viewBox='0 0 7 7'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0.646447 5.64645C0.451184 5.84171 0.451184 6.15829 0.646447 6.35355C0.841709 6.54882 1.15829 6.54882 1.35355 6.35355L0.646447 5.64645ZM6.5 1C6.5 0.723858 6.27614 0.500001 6 0.500001L1.5 0.500001C1.22386 0.500001 1 0.723858 1 1C1 1.27614 1.22386 1.5 1.5 1.5L5.5 1.5L5.5 5.5C5.5 5.77614 5.72386 6 6 6C6.27614 6 6.5 5.77614 6.5 5.5L6.5 1ZM1.35355 6.35355L6.35355 1.35355L5.64645 0.646447L0.646447 5.64645L1.35355 6.35355Z'
                      fill='#F9F9F9'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className='sectionWrapper coverSection fadeIn'>
        <div className='virtual-real-box fadeIn'>
          <h2 className='virtual-real-box__text'>
            Virtual
            <br></br>is<br></br>Real
          </h2>
        </div>
        <video
          ref={videoRef}
          muted
          loop='loop'
          data-autoplay=''
          className='cover-video-anim fadeIn'
          style={{ animationDuration: '2s' }}
          width='auto'
          height='auto'
          autoPlay
          playsInline
          preload='none'
          poster='./img/32238.jpg'
        >
          <source
            src={require('../vid/prelab preview video.mp4')}
            type='video/mp4'
            ref={videoRef}
          ></source>
        </video>
      </div>
      <div className='sectionWrapper aboutSection' id='menu-link-1'>
        <div className='clip-cont'>
          <div className='text-cont'>
            <h2
              id='basic-waypoint'
              className='text-anim text-anim--up'
              style={{ textAlign: 'center' }}
            >
              What We Do?
            </h2>
          </div>
          <div className='text-cont'>
            <h4
              className='textWrapper text-anim text-anim--up'
              style={{ transitionDelay: '0.2s' }}
            >
              We at PreLab are team of professional interior designers from
              Ukraine, specializing in virtual spaces and virtual staging of all
              kinds. We offer modern design solutions in interior spaces for
              estate professionals and home owners all over the globe.
            </h4>
          </div>
        </div>
      </div>
      <div className='sectionWrapper areasSection waypoint'>
        <div className='progress-area'></div>
        <div className='text-cont'>
          <h2 id='howwedo' className='text-anim text-anim--left'>
            How We Do It?
          </h2>
        </div>
        <div id='papa'>
          <span className='scroll-progress-percentage'></span>
        </div>
        <div className='stepsContainer fadeAnim'>
          <div className='stepsLeft'>
            <h3 style={{ textAlign: 'left' }} className='fade-in'>
              Take<br></br>photos
            </h3>
            <h4 className='stepsLeft--txt fade-in'>
              Take photos of your room in the highest quality possible.
            </h4>
            <h4 className='stepsLeft--num fade-in'>1/3</h4>
          </div>

          <div className='stepsProgresOut'>
            <div className='stepsProgress'></div>
          </div>

          <div className='stepsRight'>
            <video
              muted
              loop='loop'
              className='cover-video-anim lazy-load'
              width='640'
              height='360'
              autoPlay
              playsInline
              preload='none'
            >
              <source
                data-src={require('../vid/Comp 1.mp4')}
                type='video/mp4'
              ></source>
            </video>
          </div>
        </div>

        <div className='stepsContainer fadeAnim' style={{ marginTop: '10em' }}>
          <div className='stepsLeft'>
            <h3 style={{ textAlign: 'left' }} className='fade-in'>
              Start<br></br>New<br></br>Project
            </h3>
            <h4 className='stepsLeft--txt fade-in'>
              Use the "New Project" page to express all your preferences about
              the project, upload photos and submit your application.
            </h4>
            <h4 className='stepsLeft--num fade-in'>2/3</h4>
          </div>

          <div className='stepsProgresOut'>
            <div className='stepsProgress'></div>
          </div>

          <div className='stepsRight'>
            <video
              muted
              loop='loop'
              className='cover-video-anim lazy-load'
              width='640'
              height='360'
              autoPlay
              playsInline
              preload='none'
            >
              <source
                data-src={require('../vid/Comp 2.mp4')}
                type='video/mp4'
              ></source>
            </video>
          </div>
        </div>

        <div className='stepsContainer fadeAnim' style={{ marginTop: '10em' }}>
          <div className='stepsLeft'>
            <h3 style={{ textAlign: 'left' }} className='fade-in'>
              Get<br></br>Results
            </h3>
            <h4 className='stepsLeft--txt fade-in'>
              After receiving your submission, we are going to discuss the
              project with you. Then we will immediately begin working on your
              project to give you the best results as fast as possible.
            </h4>
            <h4 className='stepsLeft--num fade-in'>3/3</h4>
          </div>

          <div className='stepsProgresOut'>
            <div className='stepsProgress'></div>
          </div>

          <div className='stepsRight'>
            <video
              muted
              loop='loop'
              className='cover-video-anim lazy-load'
              width='640'
              height='360'
              autoPlay
              playsInline
              preload='none'
            >
              <source
                data-src={require('../vid/Comp 3.mp4')}
                type='video/mp4'
              ></source>
            </video>
          </div>
        </div>
      </div>
      <div id='scroll-target-1'></div>
      <div className='sectionWrapper gallerySection'>
        <div className='marquee'>
          <div className='marquee-track'>
            <h2 className='marquee-track-wrapper'>
              <span className='marquee-text -twox'>
                OURWORKS<span className='-green'>OURWORKS</span>
              </span>
              <span className='marquee-text -twox'>
                OURWORKS<span className='-green'>OURWORKS</span>
              </span>
              <span className='marquee-text -twox'>
                OURWORKS<span className='-green'>OURWORKS</span>
              </span>
              <span className='marquee-text -twox'>
                OURWORKS<span className='-green'>OURWORKS</span>
              </span>
              <span className='marquee-text -twox'>
                OURWORKS<span className='-green'>OURWORKS</span>
              </span>
              <span className='marquee-text -twox'>
                OURWORKS<span className='-green'>OURWORKS</span>
              </span>
              <span className='marquee-text -twox'>
                OURWORKS<span className='-green'>OURWORKS</span>
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className='sectionWrapper' style={{ background: '#fcfcfc' }}>
        <div data-type='Gallery' className='mainGallery' id='menu-link-2'>
          <figure data-type='Item' className='galleryImage grid-col-span-2'>
            <div className='imgWrapper' target id='imgWrapper-img01'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/22731.jpg'
                alt=''
                width='1280'
                height='720'
              ></img>
              <img
                className='works-img works-img--after hover-shadow spp1-cujs lazy-load'
                data-src='./img/7369.jpg'
                alt='Living room in Mid-century style with light furniture'
                width='1280'
                height='720'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage'>
            <div className='imgWrapper' target id='imgWrapper-img02'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/797.jpg'
                alt=''
                width='640'
                height='360'
              ></img>
              <img
                className='works-img works-img--after works-img--delayed hover-shadow spp1-cujs lazy-load'
                data-src='./img/28011.jpg'
                alt='Mid-century Kitchen with wooden facades'
                width='640'
                height='360'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage'>
            <div className='imgWrapper' target id='imgWrapper-img03'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/25320.jpg'
                alt=''
                width='640'
                height='360'
              ></img>
              <img
                className='works-img works-img--after hover-shadow spp1-cujs lazy-load'
                data-src='./img/15890.jpg'
                alt='Light and gender neutral kid-room with blue accent'
                width='640'
                height='360'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage grid-col-span-2'>
            <div className='imgWrapper' target id='imgWrapper-img04'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/749.jpg'
                alt=''
                width='1280'
                height='720'
              ></img>
              <img
                className='works-img works-img--after works-img--delayed hover-shadow spp1-cujs lazy-load'
                data-src='./img/6730.jpg'
                alt='Mid-century bedroom with orange a accent color'
                width='1280'
                height='720'
              ></img>
            </div>
          </figure>

          <figure data-type='Item' className='galleryImage'>
            <div className='imgWrapper'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/26717.jpg'
                alt=''
                width='640'
                height='360'
              ></img>
              <img
                className='works-img works-img--after hover-shadow spp1-cujs lazy-load'
                data-src='./img/27969.jpg'
                alt='Gray Media-room with home-theater setup'
                width='640'
                height='360'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage'>
            <div className='imgWrapper'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/2263.jpg'
                alt=''
                width='640'
                height='360'
              ></img>
              <img
                className='works-img works-img--after works-img--delayed hover-shadow spp1-cujs lazy-load'
                data-src='./img/21374.jpg'
                alt='Living-room with black and yellow accent color'
                width='640'
                height='360'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage grid-col-span-2'>
            <div className='imgWrapper'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/19359.jpg'
                alt=''
                width='1280'
                height='720'
              ></img>
              <img
                className='works-img works-img--after hover-shadow spp1-cujs lazy-load'
                data-src='./img/1715.jpg'
                alt='Contemporary Living-room with bronze decor'
                width='1280'
                height='720'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage'>
            <div className='imgWrapper'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/20849.jpg'
                alt=''
                width='640'
                height='360'
              ></img>
              <img
                className='works-img works-img--after works-img--delayed hover-shadow spp1-cujs lazy-load'
                data-src='./img/27758.jpg'
                alt='Roof-top sitting area with fireplace setup'
                width='640'
                height='360'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage'>
            <div className='imgWrapper'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/4206.jpg'
                alt=''
                width='640'
                height='360'
              ></img>
              <img
                className='works-img works-img--after hover-shadow spp1-cujs lazy-load'
                data-src='./img/4424.jpg'
                alt='Contemporary dining-room with green accent'
                width='640'
                height='360'
              ></img>
            </div>
          </figure>
          <figure data-type='Item' className='galleryImage grid-col-span-2'>
            <div className='imgWrapper'>
              <span className='playIcon'></span>
              <img
                className='works-img works-img--before hover-shadow preview lazy-load'
                data-src='./img/31717.jpg'
                alt=''
                width='1280'
                height='720'
              ></img>
              <img
                className='works-img works-img--after hover-shadow spp1-cujs lazy-load'
                data-src='./img/4443.jpg'
                alt='White brick facade in midtown LA'
                width='1280'
                height='720'
              ></img>
            </div>
          </figure>
        </div>
      </div>
      <div className='sectionWrapper startpSection ' id='menu-link-3'>
        <div className='text-cont'>
          <h2 className='text-anim text-anim--up'>Start a Project</h2>
        </div>

        <div className='startpSection-container'>
          <a href='/newproject'>
            <button
              className='btnMain btnBlue'
              href='/newproject'
              id='new_project'
              style={{ margin: 'auto' }}
              // onClick={() => {
              //   openContact();
              // }}
            >
              <span>NEW PROJECT</span>
            </button>
          </a>
          <div className='socialMedia'>
            <a
              className='fa-brands fa-facebook'
              href='https://www.facebook.com/PrelabDesign'
              target='_blank'
            ></a>
            <a
              className='fa-brands fa-instagram'
              href='https://www.instagram.com/prelab.studio/'
              target='_blank'
            ></a>
            <a
              className='fa-brands fa-twitter-square'
              href='https://twitter.com/PrelabDesign'
              target='_blank'
            ></a>
            <a
              className='fa-brands fa-telegram'
              href='https://t.me/Prelab_design_bot'
              target='_blank'
            ></a>
          </div>
        </div>
      </div>
      <div
        type='footer'
        className='sectionWrapper footerSection'
        style={{ color: '#fff' }}
      >
        <div className='adLogo'>
          <a href='https://www.averyano.com/'>
            <img src='img/av-black.png' alt=''></img>
          </a>
        </div>

        <div id='rightsText'>(c) 2022. All rights reserved.</div>
        <a
          href='mailto:support@prelab.design'
          className='mail-to colorful-link'
        >
          support@prelab.design
        </a>
      </div>
      <Lightbox page={'home'} />
      <div id='scroll-end'></div>
    </>
  );
}

export default Home;
