function NotFound() {
  return (
    <div
      className="sectionWrapper"
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        background: 'rgb(255,255,255)',
      }}
    >
      <div className="notfound-card">
        <h2>404</h2>
        <h5>Page not found.</h5>
        <a href="/">Return to home</a>
      </div>
    </div>
  );
}

export default NotFound;
