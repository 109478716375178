import React from 'react';

function Cookie() {
  return (
    <div className="cookie-banner">
      <div className="cookie__message">
        We are using cookies and similar technologies to enhance your experience
        on this website. To continue browsing this website you need to accept
        cookies.
      </div>
      <div className="options-wrapper">
        <span className="allow">Allow</span>
        <span className="necessary">Allow Necessary</span>
        <span className="leave">Leave Website</span>
      </div>
    </div>
  );
}

export default Cookie;
