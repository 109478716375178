import React from 'react';
import { useEffect } from 'react';

function Lightbox({ page }) {
  useEffect(() => {
    const frontApp = document.getElementById('Lightbox');
    const lightboxBg = document.createElement('div');
    const lightboxImg = document.createElement('img');

    let lightboxCounter;
    let adlTimer;
    frontApp.appendChild(lightboxImg);
    lightboxImg.id = 'lightboxImg';
    lightboxBg.id = 'adLightbox';
    frontApp.appendChild(lightboxBg);

    const lboxImages = document.querySelectorAll('.grid-item a');

    // Event Delegation
    const applyEventListener = function (el) {
      document.querySelector(el).addEventListener('click', function (e) {
        // Matching strategy checkout-img
        if (
          (e.target.classList.contains('spp1-cujs') &&
            e.target.src.slice(-10) !== '-cloud.png') ||
          e.target.classList.contains('checkout-img')
        ) {
          // const id = e.target.getAttribute('src');
          document.querySelectorAll('.spp1-cujs').forEach((image, i, arr) => {
            if (image === e.target) {
              const lightboxArray = arr;
              lightboxCounter = i;
              lightboxBg.classList.add('active');
              showLightbox(e.target.src, lightboxCounter, lightboxArray);
            }
          });
          // const id = e.target.getAttribute('src');
          document
            .querySelectorAll('.checkout-img')
            .forEach((image, i, arr) => {
              if (image === e.target) {
                const lightboxArray = arr;
                lightboxCounter = i;
                lightboxBg.classList.add('active');
                showLightbox(e.target.src, lightboxCounter, lightboxArray);
              }
            });
        }
      });
    };
    if (page === 'newproject') {
      applyEventListener('.checkout-wrapper');
      applyEventListener('.spp1-form-img__block');
    } else if (page === 'admin') {
      applyEventListener('.adm__app');
    } else if (page === 'home') {
      applyEventListener('.mainGallery');
    }

    // lboxImages.forEach((image, i, arr) => {
    //   image.addEventListener('click', e => {
    //     e.preventDefault();
    //     const lightboxArray = arr;
    //     lightboxCounter = i;

    //     lightboxBg.classList.add('active');

    //     showLightbox(image.href, lightboxCounter, lightboxArray);

    //     // lightboxImg.naturalWidth = lightboxImg.naturalWidth /2;

    //     // showLightbox();
    //   });
    // });

    const hideLightbox = function () {
      lightboxBg.classList.remove('active');
      lightboxBg.innerHTML = '';
    };

    const showLightbox = function (url, i, arr) {
      lightboxBg.insertAdjacentHTML(
        'afterbegin',
        `
        <div class="adl-content">

          <div class="adl-close">
						<svg
						class="adl-close-circle"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17 14.2853L11.7166 9.00096L16.9981 3.71756L14.2834 1L8.99904 6.2834L3.71564 1L1 3.71756L6.28148 9.00096L1 14.2844L3.71756 17L8.99904 11.7166L14.2805 17L17 14.2853Z"
								fill="white"
								stroke="white"
								strokeLinejoin="round"
							/>
						</svg>
          </div>
          <figure>
            <img src="${url}" class="adl-img" alt="5">
            <figcaption>
            </figcaption>
          </figure>
					</div>


        `
      );

      // Query Selectors
      const adlArrowLeft = document.querySelector('.adl-arrow-left');
      const adlArrowRight = document.querySelector('.adl-arrow-right');
      const adlCounter = document.querySelector('.adl-counter');
      const adlFigure = document.querySelector('.adl-content figure');
      const adlImage = document.querySelector('.adl-img');
      const adlX = document.querySelector('.adl-close-circle');

      // Functions
      // Prev image

      const lightboxChangeImg = function (imgsrc) {
        // Remove Current Image
        const adlFigureImg = document.querySelector('.adl-content figure img');
        adlFigureImg.parentNode.removeChild(adlFigureImg);

        // Put Loading Text

        // Missing ?

        // Set Next Image
        const newImg = document.createElement('img');
        newImg.classList.add('adl-img');
        newImg.src = imgsrc;
        // newImg.width = newImg.naturalWidth;
        // newImg.height = newImg.naturalHeight;
        // const adlImg = document.querySelector('adl-img');
        // adlImg.style.width = newImg.width;
        // adlImg.style.height = newImg.height;
        adlFigure.appendChild(newImg);
      };

      // Close lbox
      lightboxBg.addEventListener('click', () => {
        hideLightbox();
      });
      adlImage.addEventListener(
        'load',
        () => {
          adlImage.classList.add('fadeIn');
        },
        { once: true }
      );
    };
  }, []);

  return <div id="Lightbox"></div>;
}

export default Lightbox;
