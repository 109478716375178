import React from 'react';

function Revealer() {
  return (
    <div className="revealer">
      <div className="revealer__layer"></div>
      <div className="revealer__layer"></div>
      <div className="revealer__layer"></div>
      <div className="revealer__layer"></div>
    </div>
  );
}

export default Revealer;
