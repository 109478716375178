import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import { initializeApp } from 'firebase/app';

import { getAuth } from 'firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBP0NlAOCzm8IRGPhmuEbRKKvPsh1t4ZDs',
  authDomain: 'tutorial-firebase-f6ffb.firebaseapp.com',
  projectId: 'tutorial-firebase-f6ffb',
  storageBucket: 'tutorial-firebase-f6ffb.appspot.com',
  messagingSenderId: '699401886472',
  appId: '1:699401886472:web:245929959443d9fc1faabd',
  measurementId: 'G-ENLE2GX3LF',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lc2xbogAAAAAPKscRXnzl8qS2Uc0sH2_cdPgC8f'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
